import { IconProps } from ".";

export const CheckIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill={fill}>
                <g fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75">
                    <path d="M12 3a9 9 0 1 0 0 18 9 9 0 1 0 0-18z" />
                    <path d="M8.44 12.339l2.167 2.167-.02-.02 4.88-4.89" />
                </g>
                <path d="M0 0h24v24H0z" fill="none" />
            </g>
        </svg>
    );
};
