import create, { EqualityChecker, GetState, StateCreator, StateSelector, StoreApi, UseStore } from "zustand";
import { createModeSlice, ModeSlice } from "./mode";
import { createLayerSlice, LayerSlice } from "./layer";
import { createLineSlice, LineSlice } from "./line";
import { createVertexSlice, VertexSlice } from "./vertex";
import { createHoleSlice, HoleSlice } from "./hole";
import { Drawing } from "./abstraction/drawing";
import { Mode, MODE_IDLE } from "./abstraction";
import { createSceneSlice, Scene, SceneSlice } from "./scene";
import { SnapElement } from "./snap";
import { devtools } from "zustand/middleware";
//import { produce } from "immer/src/immer";
import { produce } from "immer/dist/immer.umd.development.js";
import { createHistorySlice, HistorySlice } from "./history";
import { CatalogSlice, createCatalogSlice } from "./catalog/implementation";
import { createContext, PropsWithChildren, useContext, useState } from "react";
import { Emitter } from "./abstraction/emitter";

// Turn the set method into an immer proxy
const immer = (config) => (set, get, api) =>
    config(
        (partial, replace, name) => {
            const nextState = typeof partial === "function" ? produce(partial) : partial;
            return set(nextState, replace, name);
        },
        get,
        api,
    );

export type State = ModeSlice &
    LayerSlice &
    LineSlice &
    VertexSlice &
    HistorySlice &
    HoleSlice &
    SceneSlice &
    CatalogSlice & {
        number: number;
        mode: Mode;
        scene: Scene;
        snapElements: SnapElement[];
        activeSnap?: SnapElement;
        drawing?: Drawing;
    };

export function initialiseStore(emitter: Emitter) {
    return create<State>(
        devtools(
            immer((set) => ({
                number: 0,
                mode: MODE_IDLE,
                scene: {
                    height: 1000,
                    width: 1000,
                    unit: "cm",
                    layers: {},
                },
                snapElements: [],
                ...createModeSlice(set),
                ...createLayerSlice(set),
                ...createLineSlice(set, emitter),
                ...createVertexSlice(set),
                ...createHoleSlice(set),
                ...createHistorySlice(set),
                ...createSceneSlice(set),
                ...createCatalogSlice(set),
            })),
        ),
    );
}

export const context = createContext<UseStore<State> | undefined>(undefined);
const { Provider } = context;

export function useStore() {
    const useStore = useContext(context);
    return useStore;
}

interface DrawingStoreProviderProps {
    store: UseStore<State>;
}

export const DrawingStoreProvider = (props: PropsWithChildren<DrawingStoreProviderProps>) => {
    const { children, store } = props;
    const [useStore] = useState<UseStore<State>>(() => store); // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state

    return <Provider value={useStore}>{children}</Provider>;
};
