import { useEffect, useState } from "react";
import { ToolbarType, DrawingIcon, WallIcon, CloseIcon, UndoIcon, RedoIcon } from "@stylister/branding";
import { useKeyPress } from "@stylister/keyboard";

interface Props {
    onProvide: (toolbar: ToolbarType) => void;
    undo: (redoable: boolean) => void;
    useCanUndo: () => boolean;
    redo: () => void;
    useCanRedo: () => boolean;
}

export const Toolbar = ({ onProvide, undo, useCanUndo, redo, useCanRedo }: Props) => {
    const canUndo = useCanUndo();
    const canRedo = useCanRedo();

    useEffect(() => {
        var toolbar: ToolbarType = {
            groups: {
                history: {
                    label: "History",
                    collapseOrder: 999,
                    collapsedIcon: <DrawingIcon fill="black" size="32" />,
                    items: {
                        undo: {
                            type: "Button",
                            label: "Undo",
                            order: 0,
                            icon: <UndoIcon fill="black" size="32" />,
                            disabled: !canUndo,
                            onClick: () => undo(true),
                        },
                        redo: {
                            type: "Button",
                            label: "Redo",
                            order: 1,
                            icon: <RedoIcon fill="black" size="32" />,
                            disabled: !canRedo,
                            onClick: () => redo(),
                        },
                    },
                },
            },
        };

        onProvide(toolbar);
    }, [canUndo, canRedo]);

    return null;
};
