import { generateId } from "../id";
import { Vertex } from "./types";
import { State } from "../store";
import { NamedSet } from "zustand/middleware";
import { Vector2 } from "../geometry";

export interface VertexSlice {
    addVertex: (layerId: string, x: number, y: number, relatedPrototype: "lines" | "areas", relatedId: string) => void;
    removeVertex: (
        layerId: string,
        vertexId: string,
        relatedPrototype: "lines" | "areas",
        relatedId: string,
        forceRemove: boolean,
    ) => void;
}

export const createVertexSlice = (set: NamedSet<State>): VertexSlice => ({
    addVertex: (layerId, x, y, relatedPrototype, relatedId) => {
        set(
            (state: State) => {
                addVertex(state, layerId, x, y, relatedPrototype, relatedId);
            },
            false,
            "addVertex",
        );
    },
    removeVertex: (layerId, vertexId, relatedPrototype, relatedId, forceRemove) =>
        set(
            (state: State) => removeVertex(state, layerId, vertexId, relatedPrototype, relatedId, forceRemove),
            false,
            "removeVertex",
        ),
});

export const addVertex = (
    state: State,
    layerId: string,
    x: number,
    y: number,
    relatedPrototype: "lines" | "areas",
    relatedId: string,
): Vertex => {
    const layer = state.scene.layers[layerId];

    let vertex = Object.keys(layer.vertices)
        .map((key) => layer.vertices[key])
        .find((v) => new Vector2(v.x, v.y).samePoints(new Vector2(x, y)));

    if (vertex) {
        vertex[relatedPrototype].push(relatedId);
    } else {
        const vertexId = generateId();
        vertex = new Vertex(vertexId, "Vertex", x, y);
        vertex[relatedPrototype] = [relatedId];
    }

    layer.vertices[vertex.id] = vertex;
    return vertex;
};

export const removeVertex = (
    state: State,
    layerId: string,
    vertexId: string,
    relatedPrototype: string,
    relatedId: string,
    forceRemove: boolean,
) => {
    const layer = state.scene.layers[layerId];
    const vertex = layer.vertices[vertexId];

    if (vertex) {
        if (relatedPrototype && relatedId) {
            const related = vertex[relatedPrototype];
            const index = related.findIndex((id) => relatedId === id);
            related.splice(index, 1);
        }

        const inUse = vertex.areas.length || vertex.lines.length;

        if (inUse && !forceRemove) {
            layer.vertices[vertexId] = vertex;
        } else {
            delete layer.vertices[vertexId];
        }
    }
};
