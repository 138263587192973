import classnames from "classnames";
import { Popover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ReactElement, FC } from "react";

interface Props {
    label: string;
    hide?: boolean;
    contextual?: boolean;
    collapsed: boolean;
    collapsedIcon: ReactElement;
}

export const ToolbarGroup: FC<Props> = ({ label, hide, contextual, collapsed, collapsedIcon, children }) => {
    if (hide) {
        return null;
    }

    const base = (
        <div className="inline-flex flex-col">
            <div className={classnames("flex p-2", contextual ? "ring-1 ring-picton-blue-200 ring-inset" : "")}>
                {children}
            </div>
            <div
                className={classnames(
                    "flex justify-center items-center text-xs h-6",
                    contextual ? "bg-picton-blue-200" : "", //"bg-gray-100 border-t -mt-px"
                )}
            >
                {label}
            </div>
        </div>
    );

    return collapsed ? (
        <div className="flex py-2 px-2">
            <Collapsed icon={collapsedIcon} label={label}>
                {base}
            </Collapsed>
        </div>
    ) : (
        base
    );
};

interface CollapsedProps {
    icon: ReactElement;
    label: string;
}

const Collapsed: FC<CollapsedProps> = ({ icon, label, children }) => {
    return (
        <Popover className="relative">
            <Popover.Button className="inline-flex flex-col justify-center items-center w-full text-xs px-2 pt-1 pb-2 hover:bg-picton-blue-100 hover:ring-picton-blue-500 hover:ring-1 cursor-pointer rounded">
                <div className="flex flex-col justify-center">
                    <div className="flex justify-center">{icon}</div>
                    <div className="flex justify-center text-xs">{label}</div>
                </div>
                <div className="flex justify-center">
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </div>
            </Popover.Button>

            <Popover.Panel className="absolute z-10 mt-2 -mx-2 bg-white border border-gray-200">
                {children}
            </Popover.Panel>
        </Popover>
    );
};
