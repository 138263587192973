import { chain } from "lodash";
import {
    closestPointFromLine,
    closestPointFromLineSegment,
    distancePointFromLine,
    distancePointFromLineSegment,
    twoLinesIntersection,
    Vector2,
} from "../geometry";
import { immerable } from "immer";

export const SNAP_POINT = "SNAP_POINT";
export const SNAP_LINE = "SNAP_LINE";
export const SNAP_SEGMENT = "SNAP_SEGMENT";
export const SNAP_GRID = "SNAP_GRID";
export const SNAP_GUIDE = "SNAP_GUIDE";

export const SNAP_MASK = {
    SNAP_POINT: true,
    SNAP_LINE: true,
    SNAP_SEGMENT: true,
    SNAP_GRID: false,
    SNAP_GUIDE: true,
};

export interface Snap {
    nearestPoint(x: number, y: number): { x: number; y: number; distance: number };
    isNear(x: number, y: number, distance: number): boolean;
}

interface NearestPoint {
    x: number;
    y: number;
    distance: number;
}

interface NearestSnap {
    snap: SnapElement;
    point: NearestPoint;
}

export type SnapElement = PointSnap | LineSnap | LineSegmentSnap | GridSnap;

export class PointSnap implements Snap {
    [immerable] = true;

    type: "point";
    x = -1;
    y = -1;
    radius = 1;
    priority = 1;
    related: string | null;

    constructor(x: number, y: number, radius: number, priority: number, related: string | null) {
        this.type = "point";
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.priority = priority;
        this.related = related;
    }

    nearestPoint(x: number, y: number): NearestPoint {
        return {
            x: this.x,
            y: this.y,
            distance: new Vector2(this.x, this.y).dist(new Vector2(x, y)),
        };
    }

    isNear(x: number, y: number, distance: number): boolean {
        // tslint:disable-next-line:no-bitwise
        return ~(this.x - x) + 1 < distance && ~(this.y - y) + 1 < distance;
    }
}

export class LineSnap implements Snap {
    [immerable] = true;

    type: "line";
    a = -1;
    b = -1;
    c = -1;
    radius = 1;
    priority = 1;
    related: string | null;

    constructor(a: number, b: number, c: number, radius: number, priority: number, related: string | null) {
        this.type = "line";
        this.a = a;
        this.b = b;
        this.c = c;
        this.radius = radius;
        this.priority = priority;
        this.related = related;
    }

    nearestPoint(x: number, y: number): NearestPoint {
        return {
            ...closestPointFromLine(this.a, this.b, this.c, x, y),
            distance: distancePointFromLine(this.a, this.b, this.c, x, y),
        };
    }

    isNear(x: number, y: number, distance: number): boolean {
        return true;
    }
}

export class LineSegmentSnap implements Snap {
    [immerable] = true;

    type: "line-segment";
    x1 = -1;
    y1 = -1;
    x2 = -1;
    y2 = -1;
    radius = 1;
    priority = 1;
    related: string | null;

    constructor(
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        radius: number,
        priority: number,
        related: string | null,
    ) {
        this.type = "line-segment";
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;
        this.radius = radius;
        this.priority = priority;
        this.related = related;
    }

    nearestPoint(x: number, y: number): NearestPoint {
        return {
            ...closestPointFromLineSegment(this.x1, this.y1, this.x2, this.y2, x, y),
            distance: distancePointFromLineSegment(this.x1, this.y1, this.x2, this.y2, x, y),
        };
    }

    isNear(x: number, y: number, distance: number): boolean {
        return true;
    }
}

export class GridSnap implements Snap {
    [immerable] = true;

    type: "grid";
    x = -1;
    y = -1;
    radius = 1;
    priority = 1;
    related: string | null;

    constructor(x: number, y: number, radius: number, priority: number, related: string | null) {
        this.type = "grid";
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.priority = priority;
        this.related = related;
    }

    nearestPoint(x: number, y: number): NearestPoint {
        return {
            x: this.x,
            y: this.y,
            distance: new Vector2(this.x, this.y).dist2(new Vector2(x, y)),
        };
    }

    isNear(x: number, y: number, distance: number): boolean {
        // tslint:disable-next-line:no-bitwise
        return ~(this.x - x) + 1 < distance && ~(this.y - y) + 1 < distance;
    }
}

export function nearestSnap(snapElements: SnapElement[], x: number, y: number): NearestSnap {
    // let filter = {
    //     point: snapMask.get(SNAP_POINT),
    //     line: snapMask.get(SNAP_LINE),
    //     "line-segment": snapMask.get(SNAP_SEGMENT),
    //     grid: snapMask.get(SNAP_GRID),
    // };

    const value = chain(snapElements)
        .filter((el) => {
            return el.isNear(x, y, el.radius);
        })
        .map((snap) => {
            return { snap, point: snap.nearestPoint(x, y) };
        })
        .filter(({ snap: { radius }, point: { distance } }) => {
            return distance < radius;
        })
        .sort(
            (
                { snap: { priority: p1 }, point: { distance: d1 } },
                { snap: { priority: p2 }, point: { distance: d2 } },
            ) => {
                return p1 === p2 ? (d1 < d2 ? -1 : 1) : p1 > p2 ? -1 : 1;
            },
        )
        .first()
        .value();

    return value;
}

export function addPointSnap(
    snapElements: SnapElement[],
    x: number,
    y: number,
    radius: number,
    priority: number,
    related: string | null,
): void {
    snapElements.push(new PointSnap(x, y, radius, priority, related));
}

export function addLineSnap(
    snapElements: SnapElement[],
    a: number,
    b: number,
    c: number,
    radius: number,
    priority: number,
    related: string | null,
): void {
    const alreadyPresent = snapElements.some((el) => el.type === "line" && a === el.a && b === el.b && c === el.c);

    if (alreadyPresent) {
        return;
    }

    snapElements.forEach((snap) => {
        if (snap.type === "line") {
            const intersection = twoLinesIntersection(snap.a, snap.b, snap.c, a, b, c);

            if (intersection !== null) {
                addPointSnap(snapElements, intersection.x, intersection.y, 20, 40, null);
            }
        }
    });

    snapElements.push(new LineSnap(a, b, c, radius, priority, related));
}

export function addLineSegmentSnap(
    snapElements: SnapElement[],
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    radius: number,
    priority: number,
    related: string | null,
): void {
    snapElements.push(new LineSegmentSnap(x1, y1, x2, y2, radius, priority, related));
}

export function addGridSnap(
    snapElements: SnapElement[],
    x: number,
    y: number,
    radius: number,
    priority: number,
    related: string | null,
): void {
    snapElements.push(new GridSnap(x, y, radius, priority, related));
}
