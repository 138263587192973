import { NamedSet } from "zustand/middleware";
import { MODE_IDLE } from "../abstraction";
import { State } from "../store";

export interface SceneSlice {
    selectNoTool: () => void;
}

export const createSceneSlice = (set: NamedSet<State>): SceneSlice => ({
    selectNoTool: () =>
        set(
            (state: State) => {
                state.mode = MODE_IDLE;
                state.drawing = undefined;
            },
            false,
            "selectNoTool",
        ),
});
