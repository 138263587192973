import { ReactElement } from "react";
import classnames from "classnames";

interface Props {
    label: string;
    icon: ReactElement;
    value: boolean;
    onClick?: () => void;
}

export const ToolbarToggleButton = ({ label, icon, value, onClick }: Props) => {
    return (
        <div
            className={classnames(
                "flex px-2 py-1 hover:bg-picton-blue-100 hover:ring-picton-blue-500 hover:ring-1 cursor-pointer rounded flex-col w-16",
                value ? "ring-picton-blue-500 ring-1 bg-picton-blue-100" : "",
            )}
            onClick={onClick}
        >
            <div className="flex justify-center">{icon}</div>
            <div className="flex justify-center text-xs">{label}</div>
        </div>
    );
};
