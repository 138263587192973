import { GetState, SetState } from "zustand";
import { Mode } from "../abstraction";
import { State, useStore } from "../store";
import { NamedSet } from "zustand/middleware";

export interface ModeSlice {
    setMode: (mode: Mode) => void;
}

export const createModeSlice = (set: NamedSet<State>): ModeSlice => ({
    setMode: (mode: Mode) =>
        set(
            (state) => {
                state.mode = mode;
            },
            false,
            "setMode",
        ),
});

export const useMode = (): Mode => {
    const store = useStore();
    const mode = store((state) => state.mode);
    return mode;
};
