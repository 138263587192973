import { IconProps } from ".";

export const DoorIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill={fill}>
                <g>
                    <path d="M18.3,3.9L32,10.3v26.9l-13.7,7.9V3.9z M19.9,42.2l10.5-6l0.1-24.8L19.9,6.7V42.2z" />
                    <path d="M31.7,37l-13.1,7.6V4.4l13.1,6.1V37z M19.6,42.8l11.1-6.4l0.1-25.2L19.6,6.3V42.8z" />
                </g>
                {/* <circle cx="22.3" cy="25" r="0.5" /> */}
                <rect x="7.9" y="36.4" width="11.2" height="0.7" />
                <rect x="31.7" y="36.4" width="6.9" height="0.7" />
                <rect x="34.1" y="8.1" width="0.7" height="28.5" />
                <rect x="12.6" y="7.7" width="0.7" height="28.9" />
                <rect x="26" y="7.7" width="8.8" height="0.7" />
                <rect x="12.9" y="7.7" width="6.6" height="0.7" />
                <rect x="15.1" y="10" width="0.7" height="26.5" />
                <rect x="15.7" y="10" width="3" height="0.7" />
            </g>
        </svg>
    );
};
