import Decimal from "decimal.js";
import { zip, first } from "lodash";
import { MODE_DRAWING_LINE, MODE_WAITING_DRAWING_LINE } from "../abstraction";
import { Vector2, intersect, horizontalLine, verticalLine } from "../geometry";
import { Drawing } from "../abstraction/drawing";
import { GetState, SetState } from "zustand";
import { State } from "../store";
import { Line } from "./types";
import { generateId } from "../id";
import { generateName } from "../name";
import { NamedSet } from "zustand/middleware";
import { removeElement, selectElement, selectLayer, unselectAll, unselectInLayer } from "../layer";
import { addVertex, removeVertex, Vertex } from "../vertex";
import { saveHistory } from "../history/implementation";
import { addLineSnap, nearestSnap, sceneSnapElements, SnapElement } from "../snap";
import {} from "piral";
import { Emitter } from "../abstraction/emitter";

export interface LineSlice {
    // createLine: (layerId: string, type: string, x1: number, y1: number, x2: number, y2: number) => Line;
    selectLine: (layerId: string, lineId: string) => void;
    // removeLine: (layerId: string, lineId: string) => void;
    // unselectLine: (layerId: string, lineId: string) => void;
    // splitLine: (layerId: string, lineId: string, x: number, y: number) => Line[];
    // addFromPoints: (layerId: string, type: string, points: Vector2[], holes: {}) => Line[];
    // createAvoidingIntersections: (
    //     layerId: string,
    //     type: string,
    //     x0: number,
    //     y0: number,
    //     x1: number,
    //     y1: number,
    //     oldHoles: {},
    // ) => Line[];
    //replaceVertex: (layerId: string, lineId: string, vertexIndex: number, x: number, y: number) => void;
    selectToolDrawingLine: (sceneComponentType: string) => void;
    beginDrawingLine: (layerId: string, x: number, y: number) => void;
    updateDrawingLine: (x: number, y: number) => void;
    endDrawingLine: (x: number, y: number) => void;
}

export const createLineSlice = (set: NamedSet<State>, emitter: Emitter): LineSlice => ({
    selectLine: (layerId, lineId) => set((state: State) => selectLine(state, layerId, lineId), false, "selectLine"),
    // unselectLine: (layerId, lineId) =>
    //     set((state: State) => unselectLine(state, layerId, lineId), false, "unselectLine"),
    selectToolDrawingLine: (sceneComponentType: string) =>
        set(
            (state: State) => {
                state.mode = MODE_WAITING_DRAWING_LINE;
                state.drawing = new Drawing(sceneComponentType);
            },
            false,
            "selectToolDrawingLine",
        ),
    beginDrawingLine: (layerId, x, y) =>
        set(
            (state: State) => {
                // saveHistory(state);

                // todo: snap mask???? see original code: https://github.com/cvdlab/react-planner/blob/efa710e2840f55f4670ceff2c16384a7d43b42bb/src/class/line.js#L246
                const snapElements: SnapElement[] = [];
                sceneSnapElements(state, snapElements);

                const snap = nearestSnap(snapElements, x, y);

                if (snap) {
                    ({ x, y } = snap.point);
                }

                let a: number, b: number, c: number;
                ({ a, b, c } = horizontalLine(y));
                addLineSnap(snapElements, a, b, c, 10, 3, null);
                ({ a, b, c } = verticalLine(x));
                addLineSnap(snapElements, a, b, c, 10, 3, null);

                unselectAll(state, layerId);

                state.drawing.dirty = true;
                state.drawing.layerId = layerId;

                const line = createLine(state, emitter, layerId, state.drawing.type, x, y, x, y);

                selectLine(state, layerId, line.id);

                state.mode = MODE_DRAWING_LINE;
                state.snapElements = snapElements;
                state.activeSnap = snap ? snap.snap : undefined;
            },
            false,
            "beginDrawingLine",
        ),
    updateDrawingLine: (x, y) =>
        set(
            (state: State) => {
                const snap = nearestSnap(state.snapElements, x, y);

                if (snap) {
                    ({ x, y } = snap.point);
                }

                const layerId = state.drawing!.layerId!;
                const layer = state.scene.layers[layerId];
                const lineId = first(layer.selected.lines)!;

                replaceVertex(state, layerId, lineId, 1, x, y);

                selectLine(state, layerId, lineId);
                state.activeSnap = snap ? snap.snap : undefined;
            },
            false,
            "updateDrawingLine",
        ),
    endDrawingLine: (x, y) =>
        set(
            (state: State) => {
                // saveHistory(state);

                const snapElements = state.snapElements;
                const snap = nearestSnap(snapElements, x, y);

                if (snap) {
                    ({ x, y } = snap.point);
                }

                const layerId = state.drawing!.layerId!;
                const layer = state.scene.layers[layerId];
                const lineId = first(layer.selected.lines)!;
                const line = layer.lines[lineId];

                const v0 = layer.vertices[line.vertices[0]];

                removeLine(state, layerId, lineId);
                createAvoidingIntersections(state, emitter, layerId, line.type, v0.x, v0.y, x, y, []);
                //Drawing.detectAndUpdateAreas(state, path); enable this again

                state.mode = MODE_WAITING_DRAWING_LINE;
                state.snapElements = [];
                state.activeSnap = undefined;
            },
            false,
            "endDrawingLine",
        ),
});

export const createLine = (
    state: State,
    emitter: Emitter,
    layerId: string,
    type: string,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
) => {
    const id = generateId();

    const v0 = addVertex(state, layerId, x1, y1, "lines", id);
    const v1 = addVertex(state, layerId, x2, y2, "lines", id);

    const name = generateName(type);
    const line = new Line(id, name, [v0.id, v1.id]);

    const layer = state.scene.layers[layerId];
    layer.lines[line.id] = line;

    emitter.emit("lineCreated", {
        message: "",
    });

    return line;
};

export const replaceVertex = (
    state: State,
    layerId: string,
    lineId: string,
    vertexIndex: number,
    x: number,
    y: number,
) => {
    const vertexId = state.scene.layers[layerId].lines[lineId].vertices[vertexIndex];
    removeVertex(state, layerId, vertexId, "lines", lineId, false);
    const vertex = addVertex(state, layerId, x, y, "lines", lineId);
    state.scene.layers[layerId].lines[lineId].vertices[vertexIndex] = vertex.id;
    return vertex;
};

export const removeLine = (state: State, layerId: string, lineId: string) => {
    const layer = state.scene.layers[layerId];
    const line = layer.lines[lineId];

    if (line) {
        unselectLine(state, layerId, lineId);

        //TODO: line.holes.forEach(holeID => state = Hole.remove(state, layerID, holeID).updatedState);

        removeElement(state, layerId, "lines", lineId);

        line.vertices.forEach((vertexId: string) => {
            removeVertex(state, layerId, vertexId, "lines", lineId, false);
        });
    }
};

export const splitLine = (state: State, emitter: Emitter, layerId: string, lineId: string, x: number, y: number) => {
    const layer = state.scene.layers[layerId];
    const line = layer.lines[lineId];
    const v0 = layer.vertices[line.vertices[0]];
    const v1 = layer.vertices[line.vertices[1]];
    const x0 = v0.x;
    const y0 = v0.y;
    const x1 = v1.x;
    const y1 = v1.y;

    const line0 = createLine(state, emitter, layerId, line.type, x0, y0, x, y);
    const line1 = createLine(state, emitter, layerId, line.type, x1, y1, x, y);

    removeLine(state, layerId, lineId);
    return { line0, line1 };
};

export const addFromPoints = (
    state: State,
    emitter: Emitter,
    layerId: string,
    type: string,
    points: Vector2[],
    holes: string[],
): Line[] => {
    const sortedPoints = points.sort(({ x: x1, y: y1 }, { x: x2, y: y2 }) => (x1 === x2 ? y1 - y2 : x1 - x2));
    const zippedPoints = zip(sortedPoints.slice(0, -1), sortedPoints.slice(1));

    const pointsPair = zippedPoints.filter((pointPair) => {
        const x1 = pointPair[0] ? pointPair[0].x : undefined;
        const x2 = pointPair[1] ? pointPair[1].x : undefined;
        const y1 = pointPair[0] ? pointPair[0].y : undefined;
        const y2 = pointPair[1] ? pointPair[1].y : undefined;

        return !(x1 === x2 && y1 === y2);
    });

    const lines = [];

    pointsPair.forEach((pointPair) => {
        const line = createLine(
            state,
            emitter,
            layerId,
            type,
            pointPair[0]!.x,
            pointPair[0]!.y,
            pointPair[1]!.x,
            pointPair[1]!.y,
        );

        // if (holes) {
        //     holes.forEach(holeWithOffsetPoint => {
        //         let { x: xp, y: yp } = holeWithOffsetPoint.offsetPosition;
        //         if (Geometry.isPointOnLineSegment(x1, y1, x2, y2, xp, yp)) {
        //             let newOffset = Geometry.pointPositionOnLineSegment(x1, y1, x2, y2, xp, yp);
        //             if (newOffset >= 0 && newOffset <= 1) {
        //                 state = Hole.create(state, layerID, holeWithOffsetPoint.hole.type, line.id, newOffset, holeWithOffsetPoint.hole.properties).updatedState;
        //             }
        //         }
        //     });
        // }
        lines.push(line);
    });
    return lines;
};

const createAvoidingIntersections = (
    state: State,
    emitter: Emitter,
    layerId: string,
    type: string,
    x0: number,
    y0: number,
    x1: number,
    y1: number,
    oldHoles: string[],
): Line[] => {
    const points = [new Vector2(x0, y0), new Vector2(x1, y1)];

    const layer = state.scene.layers[layerId];

    Object.keys(layer.lines).forEach((lineId: string) => {
        const line = layer.lines[lineId];

        const [v0, v1] = line.vertices.map((vertexId) => {
            const vertex = layer.vertices[vertexId];
            return new Vector2(vertex.x, vertex.y);
        });

        const hasCommonEndpoint =
            v0.samePoints(points[0]) ||
            v0.samePoints(points[1]) ||
            v1.samePoints(points[0]) ||
            v1.samePoints(points[1]);

        const intersection = intersect(
            new Decimal(points[0].x),
            new Decimal(points[0].y),
            new Decimal(points[1].x),
            new Decimal(points[1].y),
            new Decimal(v0.x),
            new Decimal(v0.y),
            new Decimal(v1.x),
            new Decimal(v1.y),
        );

        if (intersection.type === "colinear") {
            if (!oldHoles) {
                oldHoles = [];
            }

            // let orderedVertices = Geometry.orderVertices(points);
            // reducedState.getIn(['instance', ...path, 'lines', line.id, 'holes']).forEach(holeID => {
            //     let hole = reducedState.getIn(['instance', ...path, 'holes', holeID]);
            //     let oldLineLength = Geometry.pointsDistance(v0.x, v0.y, v1.x, v1.y);
            //     let offset = Geometry.samePoints(orderedVertices[1], line.vertices.get(1)) ? (1 - hole.offset) : hole.offset;
            //     let offsetPosition = Geometry.extendLine(v0.x, v0.y, v1.x, v1.y, oldLineLength * offset);
            //     oldHoles.push({ hole, offsetPosition });
            // });
            removeLine(state, layerId, line.id);

            points.push(new Vector2(v0.x, v0.y), new Vector2(v1.x, v1.y));
        }

        if (intersection.type === "intersect" && !hasCommonEndpoint) {
            splitLine(state, emitter, layerId, line.id, intersection.x.toNumber(), intersection.y.toNumber());
            points.push(new Vector2(intersection.x.toNumber(), intersection.y.toNumber()));
        }
    });

    const lines = addFromPoints(state, emitter, layerId, type, points, oldHoles);
    return lines;
};

export const unselectLine = (state: State, layerId: string, lineId: string) => {
    const layer = state.scene.layers[layerId];
    const line = layer.lines[lineId];

    if (line) {
        unselectInLayer(state, layerId, "vertices", line.vertices[0]);
        unselectInLayer(state, layerId, "vertices", line.vertices[1]);
        unselectInLayer(state, layerId, "lines", lineId);
    }
};

export const selectLine = (state: State, layerId: string, lineId: string) => {
    selectLayer(state, layerId);

    const layer = state.scene.layers[layerId];
    const line = layer.lines[lineId];

    selectElement(state, layerId, "lines", lineId);
    selectElement(state, layerId, "vertices", line.vertices[0]);
    selectElement(state, layerId, "vertices", line.vertices[1]);
};
