import { MODE_DRAWING_HOLE } from "../abstraction";
import { GetState, SetState } from "zustand";
import { State } from "../store";

export interface HoleSlice {
    selectToolDrawingHole: () => void;
}

export const createHoleSlice = (set: SetState<State>): HoleSlice => ({
    selectToolDrawingHole: () =>
        set((state: State) => {
            state.mode = MODE_DRAWING_HOLE;
        }),
});
