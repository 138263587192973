import { IconProps } from ".";

export const WallIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill={fill}>
                <path d="m27.2 7.8-15.5 8.9v18.1l9.1 5.3 15.5-8.9v-18.2l-9.1-5.2zm7.8 22.6-14.1 8.1-7.8-4.5v-16.5l14.1-8.1 7.8 4.5v16.5z" />
                <path d="M21.2,39.5l-8.8-5.1V16.7l8.8,5.1V39.5z M13.1,34l7.4,4.3V22.2l-7.4-4.3V34z" />
                <path d="m20.6 39.5v-17.7l15.1-8.8v17.8l-15.1 8.7zm0.6-17.3v16.2l13.8-8v-16.2l-13.8 8z" />
                <path d="m20.9 22.3-8.8-5.1 15.1-8.7 8.8 5.1-15.1 8.7zm-7.4-5 7.4 4.3 13.7-7.9-7.4-4.3-13.7 7.9z" />
            </g>
        </svg>
    );
};
