import { NamedSet } from "zustand/middleware";
import { State } from "../store";

interface CatalogItem {
    name: string;
    prototype: string;
}

export interface CatalogSlice {
    catalog: Record<string, CatalogItem>;
    register: (catalogItem: CatalogItem) => void;
}

export const createCatalogSlice = (set: NamedSet<State>): CatalogSlice => ({
    catalog: {},
    register: (catalogItem: CatalogItem) =>
        set(
            (state: State) => {
                state.catalog[catalogItem.name] = catalogItem;
            },
            false,
            "register",
        ),
});
