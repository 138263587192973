import { IconProps } from ".";

export const DrawingIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
            <g fill={fill}>
                <g fill="none" stroke={fill} strokeWidth="1">
                    <path d="M10.631 17.437l8.489-8.489a.999.999 0 0 1 1.414 0l1.078 1.078a.999.999 0 0 1 0 1.414l-8.491 8.491a1.999 1.999 0 0 1-.955.532l-1.648.389a.67.67 0 0 1-.806-.805l.386-1.651c.085-.363.27-.695.533-.959z" />
                    <path d="M17.53 10.53l2.5 2.5" />
                    <path d="M7 21h-.151A4.849 4.849 0 0 1 2 16.151a4.848 4.848 0 0 1 2.88-4.431l3.96-1.76A3.637 3.637 0 0 0 7.363 3H7" />
                </g>
                <path d="M0 0h24v24H0z" fill="none" />
            </g>
        </svg>
    );
};
