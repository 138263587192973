import { IconProps } from "./types";

export const UndoIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" stroke={fill} strokeWidth={1.5}>
                <path d="M12.3,15.1l6-6" />
                <path d="M12.3,15.1l6,6" />
                <path d="M12.3,15.1h12.1c6.7,0,12.1,5.4,12.1,12.1S31,39.3,24.4,39.3s-12.1-5.4-12.1-12.1" />
            </g>
        </svg>
    );
};
