import { memo, useMemo } from "react";
import { useStore } from "../store";
import shallow from "zustand/shallow";
import { CircleGeometry } from "three";

interface Props {
    vertexId: string;
    layerId: string;
}

export const VertexComponent = memo(({ vertexId, layerId }: Props) => {
    const store = useStore();

    const vertex = store((state) => state.scene.layers[layerId].vertices[vertexId], shallow);

    const geometry = useMemo(() => {
        return new CircleGeometry(3);
    }, []);

    return (
        <mesh position={[vertex.x, vertex.y, 0]}>
            <primitive attach="geometry" object={geometry} />
            <lineBasicMaterial color={"black"} transparent={true} />
        </mesh>
    );
});
