import { FC, Fragment, ReactElement, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline";
import { SidebarCategoryHeader } from "./sidebarCategoryHeader";

interface Props {
    menuHeader?: ReactElement;
    menuCategories?: NavigationCategory[];
    breadcrumbs?: ReactElement;
}

interface NavigationCategory {
    label: string;
    items?: ReactElement;
}

export const SidebarLayout: FC<Props> = ({ menuHeader, menuCategories, breadcrumbs, children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <div className="h-screen flex overflow-hidden">
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    static
                    className="fixed inset-0 flex z-40 xl:hidden"
                    open={sidebarOpen}
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gradient-to-b from-rhino-500 to-ebony-clay-500" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full hover:bg-picton-blue-100 hover:ring-picton-blue-500 hover:ring-1"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 flex items-center px-4">{menuHeader}</div>
                            <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                <nav className="">
                                    {menuCategories &&
                                        menuCategories.map((category) => (
                                            <div key={category.label}>
                                                <div className="my-4">
                                                    <SidebarCategoryHeader label={category.label} />
                                                </div>
                                                <div className="space-y-1">{category.items}</div>
                                            </div>
                                        ))}
                                </nav>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden xl:flex xl:flex-shrink-0">
                <div className="flex flex-col w-64 bg-gradient-to-b from-rhino-500 to-ebony-clay-500">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col h-0 flex-1">
                        <div className="flex items-center justify-center flex-shrink-0 py-4">{menuHeader}</div>
                        <div className="flex-1 flex flex-col overflow-y-auto">
                            <nav className="flex-1 ml-2 py-4">
                                {menuCategories &&
                                    menuCategories.map((category) => (
                                        <div key={category.label}>
                                            <div className="my-4">
                                                <SidebarCategoryHeader label={category.label} />
                                            </div>
                                            <div className="space-y-1">{category.items}</div>
                                        </div>
                                    ))}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                {/* <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b">
                    <button
                        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none hover:bg-picton-blue-100 hover:ring-picton-blue-500 hover:ring-1 xl:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    <div className="flex-1 px-8 flex justify-between items-center">{breadcrumbs}</div>
                </div> */}
                <main className="flex flex-col flex-1 relative overflow-y-auto focus:outline-none">{children}</main>
            </div>
        </div>
    );
};
