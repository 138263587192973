import { immerable } from "immer";
import { Selected } from "../abstraction/selected";
import { Area } from "../area";
import { Hole } from "../hole";
import { Line } from "../line";
import { Vertex } from "../vertex";

export class Layer {
    [immerable] = true;

    id: string;
    altitude: number;
    order: number;
    opacity: number;
    name: string;
    visible: boolean;
    vertices: Record<string, Vertex>;
    lines: Record<string, Line>;
    holes: Record<string, Hole>;
    areas: Record<string, Area>;
    selected: Selected;

    constructor(id: string, name: string, altitude: number) {
        this.id = id;
        this.name = name;
        this.altitude = altitude;
        this.order = 0;
        this.opacity = 1;
        this.visible = true;
        this.vertices = {};
        this.lines = {};
        this.holes = {};
        this.areas = {};
        this.selected = {
            areas: [],
            holes: [],
            lines: [],
            vertices: [],
        };
    }
}
