import { IconProps } from "./types";

export const ConstructionIcon = ({ fill, size }: IconProps) => {
    // return (
    //     <svg height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    //         <g fill={fill}>
    //             <g fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
    //                 <path d="M3.5 19h.7c1.175 0 2.323.348 3.3 1 .977.652 2.125 1 3.3 1h2.4a5.954 5.954 0 0 0 3.3-1c.977-.652 2.125-1 3.3-1h.7a1.5 1.5 0 0 0 1.5-1.5 1.5 1.5 0 0 0-1.5-1.5h-17a1.5 1.5 0 0 0 0 3z" />
    //                 <path d="M7 5.8V8a1.5 1.5 0 0 0 3 0V6a2 2 0 1 1 4 0v2a1.5 1.5 0 0 0 3 0V5.8" />
    //                 <path d="M13.732 5H14a6 6 0 0 1 6 6v5" />
    //                 <path d="M10.268 5H10a6 6 0 0 0-6 6v5" />
    //             </g>
    //             <path d="M0 0h24v24H0z" fill="none" />
    //         </g>
    //     </svg>
    // );

    // return (
    //     <svg height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    //         <g fill={fill}>
    //             <path d="M9.73 7.885L7.735 5.89a1.693 1.693 0 0 1 2.394-2.394l1.995 1.996L9.73 7.885z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    //             <path d="M15.133 5.31l3.557 3.556a2 2 0 0 1 0 2.828l-4.291 4.291a1 1 0 0 1-1.414 0l-4.971-4.97a1 1 0 0 1 0-1.415l4.29-4.29a2 2 0 0 1 2.829 0z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    //             <path d="M18.11 16.267l-1.995-1.996 2.394-2.394 1.996 1.995a1.693 1.693 0 0 1-2.394 2.394z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    //             <path d="M8.505 11.504l3.99 3.991-1.793 1.794a1 1 0 0 1-1.414 0l-2.577-2.577a1 1 0 0 1 0-1.414l1.794-1.794z" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    //             <path d="M0 0h24v24H0z" fill="none" />
    //             <g fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
    //                 <path d="M6.931 14.931L3.622 18.24a1.126 1.126 0 0 0-.309.576l-.3 1.509a.565.565 0 0 0 .664.664l1.509-.3c.218-.044.419-.151.576-.309l3.309-3.309" />
    //                 <path d="M13.51 8.1l-2 2" />
    //                 <path d="M15.9 10.49l-2 2" />
    //             </g>
    //         </g>
    //     </svg>
    // );

    return (
        <svg height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill={fill}>
                <g fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                    <path d="M18.67 6.7l-2.616-2.616a3.7 3.7 0 0 0-5.233 0L9.648 5.257a.462.462 0 0 0 .327.789h.734c.49 0 .961.195 1.308.542l.766.766v1.309l1.963 1.962 1.01-.3.952.951v1.308l.562.562c.412.412 1.08.412 1.493 0l2.431-2.432a1.054 1.054 0 0 0 0-1.493l-.561-.561h-1.309l-.951-.952z" />
                    <path d="M3.192 20.305a2.374 2.374 0 0 0 3.491-.146l8.063-9.534-1.963-1.962-9.464 8.166a2.374 2.374 0 0 0-.127 3.476z" />
                </g>
                <path d="M0 0h24v24H0z" fill="none" />
            </g>
        </svg>
    );
};
