import { immerable } from "immer";

export class DrawingObject {
    [immerable] = true;

    id: string;
    type: string;
    prototype: string;
    name: string;
    selected: boolean;
    visible: boolean;

    constructor(id: string, type: string, name: string) {
        this.id = id;
        this.type = type;
        this.prototype = ""; // todo: maybe not do this via prototype but Typeof
        this.name = name;
        this.selected = false;
        this.visible = true;
    }
}
