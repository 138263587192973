import { LayoutProps, Menu, Notifications } from "piral";
import { LogoIcon, SidebarLayout } from "@stylister/branding";
import { BrowserRouter as Router } from "react-router-dom";
import { Breadcrumbs } from "piral-breadcrumbs";
import { FC } from "react";

import "@stylister/branding/src/assets/styles/index.css";

export const App: FC<LayoutProps> = ({ children }) => {
    return (
        <>
            <Router>
                <SidebarLayout
                    menuHeader={<LogoIcon />}
                    menuCategories={[
                        {
                            label: "Project",
                            items: <Menu type="project" />,
                        },
                        {
                            label: "Edit",
                            items: <Menu type="edit" />,
                        },
                        {
                            label: "Output",
                            items: <Menu type="output" />,
                        },
                    ]}
                    breadcrumbs={<Breadcrumbs />}
                >
                    {children}
                </SidebarLayout>
                <Notifications />
            </Router>
        </>
    );
};
