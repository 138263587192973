import type { PiralPlugin } from "piral-core";
import { createElement } from "react";
import shallow from "zustand/shallow";
import { Drawing } from "../abstraction/drawing";
import { Emitter } from "../abstraction/emitter";
import { DrawingStoreProvider, initialiseStore, useStore } from "../store";
import { Vertex } from "../vertex";
import { CatalogItem, PiletDrawingApi } from "./types";

export function createDrawingApi(): PiralPlugin<PiletDrawingApi> {
    return (context) => {
        const emitter: Emitter = {
            emit: (type, args) => context.emit(type, args),
        };

        const store = initialiseStore(emitter);
        const provider = createElement(DrawingStoreProvider, { store });

        context.includeProvider(provider);

        return (api, meta) => {
            return {
                drawing: {
                    line: {
                        selectToolDrawingLine: store.getState().selectToolDrawingLine,
                    },
                    layer: {
                        createLayer: store.getState().createLayer,
                        selectLayer: store.getState().selectLayer,
                        useSelectedLayer: useSelectedLayer,
                    },
                    scene: {
                        selectNoTool: store.getState().selectNoTool,
                    },
                    history: {
                        useCanUndo,
                        undo: store.getState().undo,
                        useCanRedo,
                        redo: store.getState().redo,
                    },
                    catalog: {
                        register: (catalogItem: CatalogItem) => {
                            switch (catalogItem.prototype) {
                                case "line":
                            }
                            // todo
                        },
                    },
                    useDrawing,
                    useVertex,
                    useLineVertices,
                },
            };
        };
    };
}

const useDrawing = (): Drawing | undefined => {
    const store = useStore();
    const drawing = store((state) => state.drawing);
    return drawing;
};

const useVertex = (layerId: string, vertexId: string): Vertex | undefined => {
    const store = useStore();
    const vertex = store((state) => state.scene.layers[layerId].vertices[vertexId]);
    return vertex;
};

const useLineVertices = (layerId: string, lineId: string): Vertex[] | undefined => {
    const store = useStore();
    const vertices = store(
        (state) => [
            state.scene.layers[layerId].vertices[state.scene.layers[layerId].lines[lineId].vertices[0]],
            state.scene.layers[layerId].vertices[state.scene.layers[layerId].lines[lineId].vertices[1]],
        ],
        shallow,
    );

    return vertices;
};

const useCanUndo = (): boolean => {
    const store = useStore();
    const canUndo = store((state) => state.canUndo);
    return canUndo;
};

const useCanRedo = (): boolean => {
    const store = useStore();
    const canRedo = store((state) => state.canRedo);
    return canRedo;
};

const useSelectedLayer = (): string => {
    const store = useStore();
    const selectedLayer = store((state) => state.scene.selectedLayer);
    return selectedLayer;
};
