import { IconProps } from ".";

export const SecondFloorIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" stroke={fill} strokeWidth={1.5}>
                <path d="M40,26.2l-16,9.5L8,26.2" />
                <path d="M40,21.2l-16,9.5L8,21.2" />
                <path d="M40,16.5l-16,9.5L8,16.5L24,7L40,16.5z" />
            </g>
        </svg>
    );
};
