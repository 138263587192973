import { PiletApi } from "piral";
import { SidebarItem, ConstructionIcon, FurnitureIcon, ToolbarType } from "@stylister/branding";
import { ConstructionPage } from "./pages/constructionPage";
import { Toolbar } from "./components/toolbar";

export function setup(app: PiletApi) {
    app.registerMenu(
        () => (
            <SidebarItem
                icon={({ fill, size }) => <ConstructionIcon fill={fill} size={size} />}
                label="Construction"
                to="/construction"
            />
        ),
        {
            type: "edit",
            order: 0,
        },
    );

    app.registerPage("/construction", () => (
        <ConstructionPage
            ToolbarProvider={({ onProvide }) => <app.Extension name="toolbar" params={{ onProvide }} />}
        />
    ));

    app.registerBreadcrumb({ title: "Construction", path: "/construction" });

    app.registerMenu(
        () => (
            <SidebarItem
                icon={({ fill, size }) => <FurnitureIcon fill={fill} size={size} />}
                label="Furniture"
                to="/furniture"
            />
        ),
        {
            type: "edit",
            order: 1,
        },
    );

    app.registerExtension<{ onProvide: (toolbar: ToolbarType) => void }>("toolbar", ({ params }) => {
        return (
            <Toolbar
                onProvide={params.onProvide}
                undo={app.drawing.history.undo}
                useCanUndo={app.drawing.history.useCanUndo}
                redo={app.drawing.history.redo}
                useCanRedo={app.drawing.history.useCanRedo}
            />
        );
    });
}
