import { ComponentsState, ErrorComponentsState } from "piral";
import { App } from "./components/app";
import { NotFound } from "@stylister/branding";
import { MenuContainer } from "./components/menuContainer";
import { BreadcrumbItem } from "./components/breadcrumbItem";

export const errors: Partial<ErrorComponentsState> = {
    not_found: () => <NotFound />,
};

export const layout: Partial<ComponentsState> = {
    Layout: App,
    MenuContainer: MenuContainer,
    BreadcrumbItem: BreadcrumbItem,
};
