import { Menu, Transition } from "@headlessui/react";
import { Fragment, ReactElement } from "react";
import classnames from "classnames";
import { ChevronDownIcon } from "@heroicons/react/solid";

export interface DropdownItem {
    value: string;
    label: string;
    icon: ReactElement;
}

interface Props {
    items: Record<string, DropdownItem>;
    value?: string;
    onChange: (value: string) => void;
}

export const ToolbarDropdown = ({ items, value, onChange }: Props) => {
    var item = items[value];

    if (!item) {
        item = Object.values(items)[0];
    }

    return (
        <Menu as="div" className="relative inline-block text-left w-24">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className="inline-flex flex-row justify-center items-center w-full text-xs px-2 py-1 bg-picton-blue-100 ring-picton-blue-500 ring-1 cursor-pointer rounded">
                            <div className="flex flex-col justify-center">
                                <div className="flex justify-center">{item.icon}</div>
                                <div className="flex justify-center text-xs">{item.label}</div>
                            </div>
                            <div className="flex justify-center">
                                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                            </div>
                        </Menu.Button>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            static
                            className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                        >
                            {items &&
                                Object.keys(items)
                                    .map((key) => items[key])
                                    .map((item) => (
                                        <div key={item.value} className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        onClick={() => {
                                                            onChange(item.value);
                                                        }}
                                                        className={classnames(
                                                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                            "group flex items-center px-4 py-2 text-xs cursor-pointer"
                                                        )}
                                                    >
                                                        <div className="pr-2">{item.icon}</div>
                                                        {item.label}
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    ))}
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};
