import { IconProps } from "./types";

export const DeleteIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill={fill}>
                <g fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75">
                    <path d="M18.532 9H5.468C4.9 9 4.44 8.54 4.44 7.973v-.946C4.44 6.46 4.9 6 5.468 6h13.065c.567 0 1.027.46 1.027 1.027v.945C19.56 8.54 19.1 9 18.532 9z" />
                    <path d="M18 9l-.847 10.166A2 2 0 0 1 15.16 21H8.84a2 2 0 0 1-1.993-1.834L6 9" />
                    <path d="M8 6l1.224-2.447A.998.998 0 0 1 10.118 3h3.764c.379 0 .725.214.894.553L16 6" />
                    <path d="M12 12v6" />
                    <path d="M15 12l-.41 6" />
                    <path d="M9 12l.41 6" />
                </g>
                <path d="M0 0h24v24H0z" fill="none" />
            </g>
        </svg>
    );
};
