import { IconProps } from ".";

export const GroundFloorIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" stroke={fill} strokeWidth={1.5}>
                {/* <path d="M40,31.2l-16,9.5L8,31.2" /> */}
                <path d="M40,26.2l-16,9.5L8,26.2l16-9.5L40,26.2z" />
            </g>
        </svg>
    );
};
