import { GetState, SetState } from "zustand";
import { Prototype } from "../abstraction";
import { State } from "../store";
import { Layer } from "./types";
import { NamedSet } from "zustand/middleware";
import { unselectLine } from "../line";

export interface LayerSlice {
    selectLayer: (layerId: string) => void;
    createLayer: (id: string, name: string, altitude: number) => void;
    selectElement: (layerId: string, elementPrototype: Prototype, elementId: string) => void;
    unselectAll: (layerId: string) => void;
    remove: (layerId: string) => void;
    removeElement: (layerId: string, elementPrototype: Prototype, elementId: string) => void;
}

export const createLayerSlice = (set: NamedSet<State>): LayerSlice => ({
    selectLayer: (layerId) => set((state: State) => selectLayer(state, layerId), false, "selectLayer"),
    createLayer: (id, name, altitude) =>
        set(
            (state: State) => {
                name = name || `layer ${id}`;
                altitude = altitude || 0;

                const layer = new Layer(id, name, altitude);

                state.scene.selectedLayer = id;
                state.scene.layers[id] = layer;
            },
            false,
            "createLayer",
        ),
    selectElement: (layerId, elementPrototype, elementId) =>
        set((state: State) => selectElement(state, layerId, elementPrototype, elementId), false, "selectElement"),
    unselectAll: (layerId) => set((state: State) => unselectAll(state, layerId), false, "unselectAll"),
    remove: (layerId) =>
        set(
            (state: State) => {
                delete state.scene.layers[layerId];

                if (state.scene.selectedLayer === layerId) {
                    state.scene.selectedLayer = Object.keys(state.scene.layers)[0];
                }
            },
            false,
            "remove",
        ),
    removeElement: (layerId, elementPrototype, elementId) =>
        set(
            (state: State) => {
                removeElement(state, layerId, elementPrototype, elementId);
            },
            false,
            "removeElement",
        ),
});

export const unselectAll = (state: State, layerId: string) => {
    const layer = state.scene.layers[layerId];
    const { lines, holes, areas } = layer;

    if (lines) {
        Object.keys(lines).forEach((lineId) => unselectLine(state, layerId, lineId));
    }

    // if (holes) { holes.forEach(hole => { state = Hole.unselect(state, layerID, hole.id).updatedState; }); }
    // if (items) { items.forEach(item => { state = Item.unselect(state, layerID, item.id).updatedState; }); }
    // if (areas) { areas.forEach(area => unselectArea(state, area.id)); }
};

export const selectLayer = (state: State, layerId: string) => {
    state.scene.selectedLayer = layerId;
};

export const selectElement = (state: State, layerId: string, elementPrototype: string, elementId: string) => {
    const layer = state.scene.layers[layerId];
    layer[elementPrototype][elementId].selected = true;

    if (!layer.selected[elementPrototype].includes(elementId)) {
        layer.selected[elementPrototype].push(elementId);
    }
};

export const unselectInLayer = (state: State, layerId: string, elementPrototype: string, elementId: string) => {
    const layer = state.scene.layers[layerId];
    layer[elementPrototype][elementId].selected = false;
    layer.selected[elementPrototype] = layer.selected[elementPrototype].filter((id) => id !== elementId);
};

export const removeElement = (state: State, layerId: string, elementPrototype: string, elementId: string) => {
    delete state.scene.layers[layerId][elementPrototype][elementId];
};
// export const detectAndUpdateAreas = (state: State, path: string[]): void => {

//     let verticesArray: Array<Array<number>> = [];
//     let vertexIDToVerticesArrayIndex: Record<string, number> = {};
//     let verticesArrayIndexToVertexID: Record<number, string> = {};

//     let verticesForPath =
//         Object
//             .keys(state.vertices)
//             .map(vertexId => state.vertices[vertexId])
//             .reduce((obj, item) => { obj[item.id] = item; return obj; }, {} as Record<string, Vertex>);

//     Object
//         .keys(verticesForPath)
//         .forEach((vertexId: string) => {
//             let vertex = verticesForPath[vertexId];
//             let verticesCount = verticesArray.push([vertex.x, vertex.y]);
//             let latestVertexIndex = verticesCount - 1;
//             vertexIDToVerticesArrayIndex[vertex.id] = latestVertexIndex;
//             verticesArrayIndexToVertexID[latestVertexIndex] = vertex.id;
//         });

//     let linesForPath =
//         Object
//             .keys(state.lines)
//             .map(lineId => state.lines[lineId])
//             .reduce((obj, item) => { obj[item.id] = item; return obj; }, {} as Record<string, Line>);

//     let linesArray: Array<Array<number>> = Object
//         .keys(linesForPath)
//         .map((lineId: string) => {
//             let line = linesForPath[lineId];
//             return line.vertices.map((vertexId: string) => vertexIDToVerticesArrayIndex[vertexId]);
//         });

//     let innerCyclesByVerticesArrayIndex = calculateInnerCycles(verticesArray, linesArray);

//     let innerCyclesByVerticesID = innerCyclesByVerticesArrayIndex
//         .map((cycle: Array<number>) => cycle.map(vertexIndex => verticesArrayIndexToVertexID[vertexIndex]));

//     // All area vertices should be ordered in counterclockwise order
//     innerCyclesByVerticesID = innerCyclesByVerticesID.map((area) =>
//         isClockWiseOrder(area.map(vertexID => verticesForPath[vertexID])) ? area.reverse() : area
//     );

//     let areaIds: Array<string> = [];

//     const sameSet = (set1: string[], set2: string[]) => isEqual(sortBy(set1), sortBy(set2));

//     let areasForPath =
//         Object
//             .keys(state.areas)
//             .map(areaId => state.areas[areaId])
//             .reduce((obj, item) => { obj[item.id] = item; return obj; }, {} as Record<string, Area>);

//     // remove areas
//     Object
//         .keys(areasForPath)
//         .forEach(areaId => {
//             let area = areasForPath[areaId];
//             let areaInUse = innerCyclesByVerticesID.some(vertices => sameSet(vertices, area.vertices));
//             if (!areaInUse) {
//                 state = removeArea(state, area.id);
//             }
//         });

//     // add new areas
//     innerCyclesByVerticesID.forEach((cycle, ind) => {

//         let areaInUse = Object
//             .keys(areasForPath)
//             .map(areaId => areasForPath[areaId])
//             .find(area => sameSet(area.vertices, cycle));

//         if (areaInUse) {

//             areaIds[ind] = areaInUse.id;
//             areasForPath[areaIds[ind]].holes = [];

//         } else {

//             let areaVerticesCoords: Array<{ x: number, y: number }> =
//                 cycle.map(vertexID => verticesForPath[vertexID])
//                     .map(vertex => ({ x: vertex.x, y: vertex.y }));

//             let resultAdd = addArea(state, path, areaVerticesCoords);

//             areaIds[ind] = resultAdd.id;
//         }
//     });

// // Build a relationship between areas and their coordinates
// let verticesCoordsForArea = areaIDs.map(id => {
//     let vertices = state.getIn(['instance', ...path, 'areas', id]).vertices.map(vertexID => {
//         let { x, y } = state.getIn(['instance', ...path, 'vertices', vertexID]);
//         return List([x, y]);
//     });
//     return { id, vertices };
// });

// Find all holes for an area
// let i, j;
// for (i = 0; i < verticesCoordsForArea.length; i++) {
//     let holesList = List(); // The holes for this area
//     let areaVerticesList = verticesCoordsForArea[i].vertices.flatten().toArray();
//     for (j = 0; j < verticesCoordsForArea.length; j++) {
//         if (i !== j) {
//             let isHole = GeometryUtils.ContainsPoint(areaVerticesList,
//                 verticesCoordsForArea[j].vertices.get(0).get(0),
//                 verticesCoordsForArea[j].vertices.get(0).get(1));
//             if (isHole) {
//                 holesList = holesList.push(verticesCoordsForArea[j].id);
//             }
//         }
//     }
//     state = state.setIn(['scene', 'layers', layerID, 'areas', verticesCoordsForArea[i].id, 'holes'], holesList);
// }

// Remove holes which are already holes for other areas
// areaIDs.forEach(areaID => {
//     let doubleHoles = new Set();
//     let areaHoles = state.getIn(['scene', 'layers', layerID, 'areas', areaID, 'holes']);
//     areaHoles.forEach((areaHoleID) => {
//         let holesOfholes = state.getIn(['scene', 'layers', layerID, 'areas', areaHoleID, 'holes']);
//         holesOfholes.forEach((holeID) => {
//             if (areaHoles.indexOf(holeID) !== -1) doubleHoles.add(holeID);
//         });
//     });
//     doubleHoles.forEach(doubleHoleID => {
//         areaHoles = areaHoles.remove(areaHoles.indexOf(doubleHoleID));
//     });
//     state = state.setIn(['scene', 'layers', layerID, 'areas', areaID, 'holes'], areaHoles);
// });
//}
