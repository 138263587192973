import { horizontalLine, verticalLine } from "../geometry";
import { State } from "../store";
import { addPointSnap, addLineSnap, addLineSegmentSnap, SnapElement, addGridSnap } from "./implementation";

export function sceneSnapElements(state: State, snapElements: SnapElement[] = []) {
    const { width, height } = state.scene;
    let a, b, c;

    Object.keys(state.scene.layers)
        .map((layerId) => state.scene.layers[layerId])
        .forEach((layer) => {
            const { lines, vertices } = layer;

            Object.keys(vertices)
                .map((vertexId) => vertices[vertexId])
                .forEach(({ id, x, y }) => {
                    //if (snapMask.get(SNAP_POINT)) {
                    addPointSnap(snapElements, x, y, 10, 10, id);
                    //}

                    //if (snapMask.get(SNAP_LINE)) {
                    ({ a, b, c } = horizontalLine(y));
                    addLineSnap(snapElements, a, b, c, 10, 1, id);
                    ({ a, b, c } = verticalLine(x));
                    addLineSnap(snapElements, a, b, c, 10, 1, id);
                    //}
                });

            // if (snapMask.get(SNAP_SEGMENT)) {
            Object.keys(lines)
                .map((lineId) => lines[lineId])
                .forEach(({ id, vertices: [v0, v1] }) => {
                    const { x: x1, y: y1 } = vertices[v0];
                    const { x: x2, y: y2 } = vertices[v1];

                    addLineSegmentSnap(snapElements, x1, y1, x2, y2, 20, 1, id);
                });
            // }
        });

    // if (snapMask.get(SNAP_GRID)) {
    // const divider = 5;
    // const gridCellSize = 100 / divider;
    // const xCycle = width / gridCellSize;
    // const yCycle = height / gridCellSize;

    // for (let x = 0; x < xCycle; x++) {
    //     const xMul = x * gridCellSize;

    //     for (let y = 0; y < yCycle; y++) {
    //         const yMul = y * gridCellSize;

    //         const onXCross = !(x % divider) ? true : false;
    //         const onYCross = !(y % divider) ? true : false;

    //         //addGridSnap(snapElements, xMul, yMul, 10, onXCross && onYCross ? 15 : 10, null);
    //     }
    // }
    //   }

    // if (snapMask.get(SNAP_GUIDE)) {

    //     let horizontal = scene.getIn(['guides', 'horizontal']);
    //     let vertical = scene.getIn(['guides', 'vertical']);

    //     let hValues = horizontal.valueSeq();
    //     let vValues = vertical.valueSeq();

    //     hValues.forEach(hVal => {
    //       vValues.forEach(vVal => {
    //         addPointSnap(snapElements, vVal, hVal, 10, 10);
    //       });
    //     });

    //     hValues.forEach(hVal => addLineSegmentSnap(snapElements, 0, hVal, width, hVal, 20, 1));
    //     vValues.forEach(vVal => addLineSegmentSnap(snapElements, vVal, 0, vVal, height, 20, 1));

    //   }
}
