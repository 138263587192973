import { IconProps } from ".";

export const BasementIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" stroke={fill} strokeWidth={1.5}>
                <path d="M40.4,39.3H31v-8.7" />
                <path d="M31,30.6h-9.4v-8.7" />
                <path d="M21.6,21.9h-9.4v-8.7" />
            </g>
        </svg>
    );
};
