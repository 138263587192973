import { immerable } from "immer";

export class Drawing {
    [immerable] = true;

    type: string;
    dirty: boolean;
    layerId?: string;

    constructor(type: string) {
        this.type = type;
        this.dirty = false;
    }
}
