import { IconProps } from "./types";

export const FurnitureIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill={fill}>
                <g fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                    <path d="M18 14H6v-2.5A1.5 1.5 0 0 0 4.5 10h-1A1.5 1.5 0 0 0 2 11.5V16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4.5a1.5 1.5 0 0 0-1.5-1.5h-1a1.5 1.5 0 0 0-1.5 1.5V14z" />
                    <path d="M5 18v2" />
                    <path d="M19 18v2" />
                    <path d="M4 10V8a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v2" />
                </g>
                <path d="M0 0h24v24H0z" fill="none" />
            </g>
        </svg>
    );
};
