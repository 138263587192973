import { createContext, PropsWithChildren, ReactNode, useCallback, useEffect, useRef } from "react";
import { extend, useThree, ReactThreeFiber } from "@react-three/fiber";
import { MapControls } from "three/examples/jsm/controls/OrbitControls";

extend({ MapControls });

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            mapControls: ReactThreeFiber.Object3DNode<MapControls, typeof MapControls>;
        }
    }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const camContext = createContext<(value: boolean) => void>(() => {});

interface Props {
    children: ReactNode;
    enabled?: boolean;
    onStart?: () => void;
    onEnd?: () => void;
}

export const Controls = ({ children, enabled, onStart, onEnd }: PropsWithChildren<Props>) => {
    const { gl, camera, size, invalidate } = useThree();
    const ref = useRef<MapControls>();

    useEffect(() => {
        const current = ref.current;

        if (current) {
            current.addEventListener("change", invalidate);

            if (onStart) {
                current.addEventListener("start", onStart);
            }

            if (onEnd) {
                current.addEventListener("end", onEnd);
            }

            return () => {
                current.removeEventListener("change", invalidate);

                if (onStart) {
                    current.removeEventListener("start", onStart);
                }

                if (onEnd) {
                    current.removeEventListener("end", onEnd);
                }
            };
        }
    }, [invalidate, onStart, onEnd]);

    // note: we can also do this declaratively, but then we need to use setState
    // which is async and competes with the orbitControls event handlers
    const enableControls = useCallback(
        (value: boolean) => {
            const current = ref.current;

            if (current) {
                current.enabled = value;
            }
        },
        [ref]
    );

    // useEffect(() => {

    //     const xOffset = (size.width / 2) - 20;
    //     const yOffset = (size.height / 2) - 20;
    //     camera.position.set(xOffset, 1, yOffset);
    //     ref.current?.target.set(xOffset, 0, yOffset);

    // }, [camera, size])

    return (
        <>
            <mapControls
                ref={ref}
                args={[camera, gl.domElement]}
                // enableRotate={false}
                enabled={enabled}
                // enableDamping={false}
                // dampingFactor={0}
                // zoomSpeed={0.1}
            />
            <camContext.Provider value={enableControls}>{children}</camContext.Provider>
        </>
    );
};
