import { memo, useMemo } from "react";
import { ReactThreeFiber, extend } from "@react-three/fiber";
import * as THREE from "three";
import { useStore } from "../store";
import shallow from "zustand/shallow";
import { BufferGeometry, Vector3 } from "three";

extend({ Line_: THREE.Line });

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        // tslint:disable-next-line: interface-name
        interface IntrinsicElements {
            line_: ReactThreeFiber.Object3DNode<THREE.Line, typeof THREE.Line>;
            audio_: ReactThreeFiber.Object3DNode<THREE.Audio, typeof THREE.Audio>;
        }
    }
}

interface Props {
    lineId: string;
    layerId: string;
}

export const LineComponent = memo(({ lineId, layerId }: Props) => {
    const store = useStore();
    const vertex0 = store(
        (state) => state.scene.layers[layerId].vertices[state.scene.layers[layerId].lines[lineId].vertices[0]],
        shallow,
    );
    const vertex1 = store(
        (state) => state.scene.layers[layerId].vertices[state.scene.layers[layerId].lines[lineId].vertices[1]],
        shallow,
    );

    //if (vertex0.id === vertex1.id || samePoints(vertex0, vertex1)) return null; // avoid 0-length lines

    const geometry = useMemo(() => {
        const points = [new Vector3(vertex0.x, vertex0.y, 0), new Vector3(vertex1.x, vertex1.y, 0)];
        return new BufferGeometry().setFromPoints(points);
    }, [vertex0, vertex1]);

    return (
        <line_>
            <primitive attach="geometry" object={geometry} />
            <lineBasicMaterial color={"black"} transparent={true} />
        </line_>
    );
});
