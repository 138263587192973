import { TOOL_NONE, TOOL_PAN, TOOL_ZOOM_IN, TOOL_ZOOM_OUT, TOOL_AUTO } from "react-svg-pan-zoom";
import {
    Mode,
    MODE_2D_PAN,
    MODE_2D_ZOOM_IN,
    MODE_2D_ZOOM_OUT,
    MODE_DRAGGING_HOLE,
    MODE_DRAGGING_ITEM,
    MODE_DRAGGING_LINE,
    MODE_DRAGGING_VERTEX,
    MODE_DRAWING_HOLE,
    MODE_DRAWING_ITEM,
    MODE_DRAWING_LINE,
    MODE_IDLE,
    MODE_ROTATING_ITEM,
    MODE_WAITING_DRAWING_LINE,
} from "../abstraction/constants";

export const mode2Tool = (mode: Mode) => {
    switch (mode) {
        case MODE_2D_PAN:
            return TOOL_PAN;
        case MODE_2D_ZOOM_IN:
            return TOOL_ZOOM_IN;
        case MODE_2D_ZOOM_OUT:
            return TOOL_ZOOM_OUT;
        case MODE_IDLE:
            return TOOL_AUTO;
        default:
            return TOOL_NONE;
    }
};

export const mode2PointerEvents = (mode: Mode) => {
    return {};
    // switch (mode) {
    //     case MODE_DRAWING_LINE:
    //     case MODE_DRAWING_HOLE:
    //     case MODE_DRAWING_ITEM:
    //     case MODE_DRAGGING_HOLE:
    //     case MODE_DRAGGING_ITEM:
    //     case MODE_DRAGGING_LINE:
    //     case MODE_DRAGGING_VERTEX:
    //         return { pointerEvents: 'none' };

    //     default:
    //         return { pointerEvents: 'none' };
    // }
};

export const mode2Cursor = (mode: Mode) => {
    switch (mode) {
        case MODE_DRAGGING_HOLE:
        case MODE_DRAGGING_LINE:
        case MODE_DRAGGING_VERTEX:
        case MODE_DRAGGING_ITEM:
        case MODE_2D_PAN:
            return "move";

        case MODE_ROTATING_ITEM:
            return "ew-resize";

        case MODE_WAITING_DRAWING_LINE:
        case MODE_DRAWING_LINE:
            return "crosshair";
        default:
            return "default";
    }
};

export const mode2DetectAutopan = (mode: Mode) => {
    switch (mode) {
        case MODE_DRAWING_LINE:
        case MODE_DRAGGING_LINE:
        case MODE_DRAGGING_VERTEX:
        case MODE_DRAGGING_HOLE:
        case MODE_DRAGGING_ITEM:
        case MODE_DRAWING_HOLE:
        case MODE_DRAWING_ITEM:
            return false;

        default:
            return false;
    }
};
