import Decimal from "decimal.js";
import { EPSILON } from "../abstraction";

import { Vector2 } from "./vector2";
import { Box2 } from "./box2";
import { pointWithinRange } from "./line";

export interface ColinearIntersection {
    type: "colinear";
}

export interface ParallelIntersection {
    type: "parallel";
}

export interface IntersectIntersection {
    type: "intersect";
    x: Decimal;
    y: Decimal;
    ua: Decimal;
    ub: Decimal;
}

export interface NoIntersection {
    type: "no";
}

export type Intersection = ColinearIntersection | ParallelIntersection | IntersectIntersection | NoIntersection;

export const twoLinesIntersection = (
    a: number,
    b: number,
    c: number,
    j: number,
    k: number,
    l: number
): Vector2 | null => {
    const angularCoefficientsDiff = b * j - a * k;

    if (angularCoefficientsDiff === 0) {
        return null; // no intersection
    }

    const y = (a * l - c * j) / angularCoefficientsDiff;
    const x = (c * k - b * l) / angularCoefficientsDiff;

    return new Vector2(x, y);
};

export const intersect = (
    x1: Decimal,
    y1: Decimal,
    x2: Decimal,
    y2: Decimal,
    x3: Decimal,
    y3: Decimal,
    x4: Decimal,
    y4: Decimal
): Intersection => {
    const denom: Decimal = y4
        .minus(y3)
        .times(x2.minus(x1))
        .minus(x4.minus(x3).times(y2.minus(y1)));
    const numeA = x4
        .minus(x3)
        .times(y1.minus(y3))
        .minus(y4.minus(y3).times(x1.minus(x3))); // .div(denom);
    const numeB = x2
        .minus(x1)
        .times(y1.minus(y3))
        .minus(y2.minus(y1).times(x1.minus(x3))); // .div(denom);

    if (denom.isZero()) {
        if (numeA.isZero() && numeB.isZero()) {
            const comparator = (pa: { x: Decimal; y: Decimal }, pb: { x: Decimal; y: Decimal }) =>
                pa.x === pb.x ? pa.y.minus(pb.y).toNumber() : pa.x.minus(pb.x).toNumber();

            const line0 = [
                { x: x1, y: y1 },
                { x: x2, y: y2 },
            ].sort(comparator);
            const line1 = [
                { x: x3, y: y3 },
                { x: x4, y: y4 },
            ].sort(comparator);

            const [lineSX, lineDX] = [line0, line1].sort((lineA, lineB) => comparator(lineA[0], lineB[0]));

            if (lineSX[1].x === lineDX[0].x) {
                return { type: lineDX[0].y <= lineSX[1].y ? "colinear" : "no" };
            } else {
                return { type: lineDX[0].x <= lineSX[1].x ? "colinear" : "no" };
            }
        }

        return { type: "parallel" };
    }

    const ua = numeA.div(denom);
    const ub = numeB.div(denom);

    if (
        ua.greaterThanOrEqualTo(0 - EPSILON) &&
        ua.lessThanOrEqualTo(1 + EPSILON) &&
        ub.greaterThanOrEqualTo(0 - EPSILON) &&
        ub.lessThanOrEqualTo(1 + EPSILON)
    ) {
        const x = x1.plus(ua.times(x2.minus(x1)));
        const y = y1.plus(ua.times(y2.minus(y1)));

        return {
            type: "intersect",
            x: x,
            y: y,
            ua: ua,
            ub: ub,
        };
    }

    return { type: "no" };
};

export const vectorWithinBoundingBox = (
    x1: Decimal,
    y1: Decimal,
    x2: Decimal,
    y2: Decimal,
    boundingBox: Box2
): { start: Vector2; end: Vector2 } => {
    const intersections: Vector2[] = [];

    // top
    const intersectTop = intersect(x1, y1, x2, y2, boundingBox.x1, boundingBox.y1, boundingBox.x2, boundingBox.y2);

    if (
        intersectTop.type === "intersect" &&
        pointWithinRange(intersectTop.x, intersectTop.y, boundingBox.x1, boundingBox.x2, boundingBox.y1, boundingBox.y2)
    ) {
        intersections.push(new Vector2(intersectTop.x.toNumber(), intersectTop.y.toNumber()));
    }

    // right
    const intersectRight = intersect(x1, y1, x2, y2, boundingBox.x2, boundingBox.y1, boundingBox.x2, boundingBox.y2);

    if (
        intersectRight.type === "intersect" &&
        pointWithinRange(
            intersectRight.x,
            intersectRight.y,
            boundingBox.x1,
            boundingBox.x2,
            boundingBox.y1,
            boundingBox.y2
        )
    ) {
        intersections.push(new Vector2(intersectRight.x.toNumber(), intersectRight.y.toNumber()));
    }

    // bottom
    const intersectBottom = intersect(x1, y1, x2, y2, boundingBox.x2, boundingBox.y2, boundingBox.x1, boundingBox.y2);

    if (
        intersectBottom.type === "intersect" &&
        pointWithinRange(
            intersectBottom.x,
            intersectBottom.y,
            boundingBox.x1,
            boundingBox.x2,
            boundingBox.y1,
            boundingBox.y2
        )
    ) {
        intersections.push(new Vector2(intersectBottom.x.toNumber(), intersectBottom.y.toNumber()));
    }

    // left
    const intersectLeft = intersect(x1, y1, x2, y2, boundingBox.x1, boundingBox.y2, boundingBox.x1, boundingBox.y1);

    if (
        intersectLeft.type === "intersect" &&
        pointWithinRange(
            intersectLeft.x,
            intersectLeft.y,
            boundingBox.x1,
            boundingBox.x2,
            boundingBox.y1,
            boundingBox.y2
        )
    ) {
        intersections.push(new Vector2(intersectLeft.x.toNumber(), intersectLeft.y.toNumber()));
    }

    return {
        start: intersections[0],
        end: intersections[1],
    };
};
