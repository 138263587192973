import { Plane } from "@react-three/drei";
import { Mode, MODE_DRAWING_LINE, MODE_IDLE, MODE_WAITING_DRAWING_LINE } from "../abstraction";
import { LineComponent } from "../line";
import * as three from "three";
import { ThreeEvent } from "@react-three/fiber";
import { memo, useCallback, useEffect, useRef } from "react";
import { useStore } from "../store";
import shallow from "zustand/shallow";
import { VertexComponent } from "../vertex/component";

interface Props {
    layerId: string;
}

export const LayerComponent = memo(({ layerId }: Props) => {
    const modeRef = useRef<Mode>(MODE_IDLE);
    const store = useStore();

    useEffect(
        () =>
            store.subscribe(
                (mode: Mode) => (modeRef.current = mode),
                (state) => state.mode,
            ),
        [store],
    );

    const lines = store((state) => Object.keys(state.scene.layers[layerId].lines), shallow);
    const vertices = store((state) => Object.keys(state.scene.layers[layerId].vertices), shallow);

    const beginDrawingLine = store((state) => state.beginDrawingLine);
    const updateDrawingLine = store((state) => state.updateDrawingLine);
    const endDrawingLine = store((state) => state.endDrawingLine);
    const saveHistory = store((state) => state.saveHistory);

    const handlePointerDown = useCallback((event: ThreeEvent<PointerEvent>) => {
        //
    }, []);

    const handlePointerUp = useCallback((event: ThreeEvent<PointerEvent>) => {
        event.stopPropagation();

        const { x, y } = event.unprojectedPoint;

        switch (modeRef.current) {
            case MODE_WAITING_DRAWING_LINE:
                saveHistory();
                beginDrawingLine(layerId, x, y);
                break;

            case MODE_DRAWING_LINE:
                endDrawingLine(x, y);
                saveHistory();
                beginDrawingLine(layerId, x, y);
                break;
        }
    }, []);

    const handlePointerMove = useCallback((event: ThreeEvent<PointerEvent>) => {
        const { x, y } = event.unprojectedPoint;

        switch (modeRef.current) {
            case MODE_DRAWING_LINE:
                updateDrawingLine(x, y);
                break;
        }
    }, []);

    return (
        <Plane
            args={[10000, 10000]}
            //rotation-x={-Math.PI / 2}
            renderOrder={-300}
            // receiveShadow={true}
            // material-transparent={true}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerMove={handlePointerMove}
        >
            <meshBasicMaterial
                color="white"
                side={three.DoubleSide}
                transparent={true}
                depthFunc={3}
                depthTest={true}
                depthWrite={false}
                blending={1}
                blendDst={205}
                blendEquation={100}
                blendSrc={204}
                colorWrite={true}
            />
            {lines.map((lineId) => (
                <LineComponent key={lineId} layerId={layerId} lineId={lineId} />
            ))}
            {vertices.map((vertexId) => (
                <VertexComponent key={vertexId} layerId={layerId} vertexId={vertexId} />
            ))}
        </Plane>
    );
});
