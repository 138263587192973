import "piral/polyfills";
import { renderInstance } from "piral";
import { createBreadcrumbsApi } from "piral-breadcrumbs";
import { createDrawingApi } from "@stylister/drawing";
import { createMenuApi } from "piral-menu";
// import { createContainersApi } from "piral-containers";
import { layout, errors } from "./layout";
import { setup } from "./setup";

// change to your feed URL here (either using feed.piral.cloud or your own service)
const feedUrl = "https://feed.piral.cloud/api/v1/pilet/stylister";

const { root } = renderInstance({
    plugins: [createBreadcrumbsApi(), createMenuApi(), createDrawingApi()],
    layout,
    errors,
    requestPilets() {
        return fetch(feedUrl)
            .then((res) => res.json())
            .then((res) => res.items);
    },
});

setup(root);
