import { IconProps } from "./types";

export const RedoIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" stroke={fill} strokeWidth={1.5}>
                <path d="M30.4,9.1l6,6" />
                <path d="M30.4,21.1l6-6" />
                <path d="M36.4,27.2c0,6.7-5.4,12.1-12.1,12.1s-12.1-5.4-12.1-12.1s5.4-12.1,12.1-12.1h12.1" />
            </g>
        </svg>
    );
};
