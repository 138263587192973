import { immerable } from "immer";
import { DrawingObject } from "../abstraction/drawingObject";

export class Line extends DrawingObject {
    [immerable] = true;

    vertices: string[];
    holes: string[];
    width: number;

    constructor(id: string, name: string, vertices: string[]) {
        super(id, "line", name);

        this.vertices = vertices;
        this.holes = [];
        this.width = 1.0;
    }
}

export interface LineOptions {
    width: number;
    color: string;
}
