import { IconProps } from ".";

export const CloseIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" stroke={fill} strokeWidth={1.5}>
                <path d="M29.7,18.3L18.4,29.6" />
                <path d="M29.7,29.6L18.4,18.3" />
                <path d="M24.1,41.9L24.1,41.9c-9.9,0-18-8-18-18l0,0c0-9.9,8-18,18-18l0,0c9.9,0,18,8,18,18l0,0 C42,33.9,34,41.9,24.1,41.9z" />
            </g>
        </svg>
    );
};
