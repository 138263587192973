import { useCallback, useEffect, useRef, useState } from "react";
import { mode2Cursor } from "./mode/mode";
import { Mode, MODE_2D_PAN, MODE_IDLE } from "./abstraction";
import { Controls } from "./controls";
import { LayerComponent } from "./layer";
import { Canvas } from "@react-three/fiber";
import { context, useStore } from "./store";
import { HistoryComponent } from "./history/component";
import { SnapComponent } from "./snap";
import { useContextBridge } from "@react-three/drei";

export const DrawingSurface = () => {
    const ContextBridge = useContextBridge(context);
    const containerRef = useRef<HTMLDivElement>();
    const modeRef = useRef<Mode>(MODE_IDLE);
    const store = useStore();

    useEffect(
        () =>
            store.subscribe(
                (mode: Mode) => (modeRef.current = mode),
                (state) => state.mode,
            ),
        [store],
    );

    const setMode = store((state) => state.setMode);

    useEffect(
        () =>
            store.subscribe(
                (mode: Mode) => {
                    setEnabledControls(modeRef.current === MODE_IDLE || modeRef.current === MODE_2D_PAN);
                    containerRef.current.style.cursor = mode2Cursor(mode);
                },
                (state) => state.mode,
            ),
        [store],
    );

    const [enabledControls, setEnabledControls] = useState(true);

    const handleStart = useCallback(() => {
        setMode(MODE_2D_PAN);
    }, [setMode]);

    const handleEnd = useCallback(() => {
        setMode(MODE_IDLE);
    }, [setMode]);

    const selectedLayer = store((state) => state.scene.selectedLayer);

    return (
        <div className="m-0 p-0 flex flex-1" ref={containerRef}>
            <Canvas
                frameloop="demand"
                orthographic={true}
                dpr={[1, 2]}
                camera={{ up: [0, 0, 1] }}
                gl={{
                    antialias: true,
                    // alpha: false,
                    // stencil: false,
                    // depth: false,
                }}
                flat={true}
            >
                <ContextBridge>
                    {/* <ambientLight /> */}
                    {/* <pointLight position={[10, 10, 10]} /> */}
                    {/* <Box position={[-1.2, 0, 0]} /> */}
                    <Controls enabled={enabledControls} onStart={handleStart} onEnd={handleEnd}>
                        {selectedLayer && <LayerComponent key={selectedLayer} layerId={selectedLayer} />}
                        {/* <SnapComponent /> */}
                    </Controls>
                </ContextBridge>
            </Canvas>
            <HistoryComponent />
        </div>
    );
};
