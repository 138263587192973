import Decimal from "decimal.js";
import { positiveAngleDeg } from "./math";
import { Vector2 } from "./vector2";

export const getAngleBetween = (angle1: number, angle2: number) => {
    const correctedAngle1 = positiveAngleDeg(angle1);
    const angleBetweenLinesClockwiseAngle = positiveAngleDeg(correctedAngle1 - angle2);
    const angleBetweenLinesCounterClockwiseAngle = positiveAngleDeg(angle2 - correctedAngle1);
    const angleBetweenLines = Math.min(angleBetweenLinesClockwiseAngle, angleBetweenLinesCounterClockwiseAngle);

    return angleBetweenLines;
};

export const yFromLine = (a: number, b: number, c: number, x: number): number => {
    const y = -c / b;
    return y;
};

export const xFromLine = (a: number, b: number, c: number, y: number): number => {
    const x = c / a;
    return x * -1;
};

export const distancePointFromLine = (a: number, b: number, c: number, x: number, y: number): number => {
    // https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line

    return Math.abs(a * x + b * y + c) / Math.sqrt(a * a + b * b);
};

export const closestPointFromLine = (a: number, b: number, c: number, x: number, y: number): Vector2 => {
    // https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line

    const denom = a * a + b * b;

    return new Vector2((b * (b * x - a * y) - a * c) / denom, (a * -b * x + a * y - b * c) / denom);
};

export const distancePointFromLineSegment = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    xp: number,
    yp: number,
) => {
    // http://stackoverflow.com/a/6853926/1398836

    const A = xp - x1;
    const B = yp - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const lenSq = C * C + D * D;

    const param = lenSq !== 0 ? dot / lenSq : -1;

    let xx, yy;

    if (param < 0) {
        xx = x1;
        yy = y1;
    } else if (param > 1) {
        xx = x2;
        yy = y2;
    } else {
        xx = x1 + param * C;
        yy = y1 + param * D;
    }

    const dx = xp - xx;
    const dy = yp - yy;

    return Math.sqrt(dx * dx + dy * dy);
};

export const closestPointFromLineSegment = (x1: number, y1: number, x2: number, y2: number, xp: number, yp: number) => {
    if (x1 === x2) {
        return { x: x1, y: yp };
    }
    if (y1 === y2) {
        return { x: xp, y: y1 };
    }

    const m = (y2 - y1) / (x2 - x1);
    const q = y1 - m * x1;

    const mi = -1 / m;
    const qi = yp - mi * xp;

    const x = (qi - q) / (m - mi);
    const y = m * x + q;

    return { x, y };
};

export const horizontalLine = (y: number) => {
    return { a: 0, b: 1, c: -y };
};

export const verticalLine = (x: number) => {
    return { a: 1, b: 0, c: -x };
};

export const pointWithinRange = (
    x: Decimal,
    y: Decimal,
    xLower: Decimal,
    xUpper: Decimal,
    yLower: Decimal,
    yUpper: Decimal,
): boolean => {
    return (
        x.toNumber() >= xLower.toNumber() &&
        x.toNumber() <= xUpper.toNumber() &&
        y.toNumber() >= yLower.toNumber() &&
        y.toNumber() <= yUpper.toNumber()
    );
};

// export const vectorsToNumbers = (vectors: Vector2[]) => {
//     return vectors.reduce((accumulator, currentValue) => [...accumulator, currentValue.x, currentValue.y], []);
// }

export const angleFromVectors = (start: Vector2, end: Vector2) => {
    return end.clone().subtract(start).angleDeg();
};
