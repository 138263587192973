import { Link } from "react-router-dom";

export const NotFound = () => {
    return (
        <div className="p-4">
            <p className="error">Could not find the requested page. Are you sure it exists?</p>
            <p>
                Go back <Link to="/">to the dashboard</Link>.
            </p>
        </div>
    );
};
