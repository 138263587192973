import { immerable } from "immer";
import { DrawingObject } from "../abstraction/drawingObject";

export class Vertex extends DrawingObject {
    [immerable] = true;

    x: number;
    y: number;
    areas: string[];
    lines: string[];

    constructor(id: string, name: string, x: number, y: number) {
        super(id, "vertex", name);

        this.x = x;
        this.y = y;
        this.areas = [];
        this.lines = [];
    }
}
