import { IconProps } from "../icons";
import { NavLink } from "react-router-dom";
import { ReactElement } from "react";

interface Props {
    label: string;
    to: string;
    icon: (props: IconProps) => ReactElement;
}

export const SidebarItem = ({ label, to, icon }: Props) => {
    return (
        <NavLink
            key={label}
            to={to}
            className="group flex items-center ml-4 px-2 py-2 text-sm font-medium rounded-l-md text-gray-300 hover:bg-rhino-800 hover:text-white"
            activeClassName="bg-rhino-800 text-white"
        >
            <div className="px-2">{icon && icon({ fill: "white", size: "20" })}</div>
            {label}
        </NavLink>
    );
};
