import { ComponentType, useState } from "react";
import { Toolbar, ToolbarType } from "@stylister/branding";
import { merge } from "lodash";
import { DrawingSurface } from "@stylister/drawing";

interface Props {
    ToolbarProvider: ComponentType<{ onProvide: (toolbar: ToolbarType) => void }>;
}

export const ConstructionPage = ({ ToolbarProvider }: Props) => {
    const [mergedToolbar, setMergedToolbar] = useState<ToolbarType>();

    const onProvide = (toolbar: ToolbarType) => {
        setMergedToolbar((prevState) => {
            const merged = merge({}, prevState, toolbar);
            return merged;
        });
    };

    return (
        <>
            <ToolbarProvider onProvide={onProvide} />
            {mergedToolbar && (
                <div className="z-10">
                    <Toolbar toolbar={mergedToolbar} />
                </div>
            )}
            <DrawingSurface />
        </>
    );
};
