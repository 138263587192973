import { Children, ReactNode, FC, isValidElement } from "react";
import { Dict, MenuContainerProps, MenuItemRegistration, useGlobalState } from "piral";

export const MenuContainer: FC<MenuContainerProps> = ({ type, children }) => {
    const menuItems = useGlobalState((m) => m.registry.menuItems);

    const sortedChildren = Children.map(children, (child) => extractChild(menuItems, child))
        .filter((x) => !!x.menuItem)
        .sort((a, b) => a.menuItem?.settings.order - b.menuItem?.settings.order);

    return <>{sortedChildren.filter((x) => x.menuItem.settings.type == type).map((m) => m.child)}</>;
};

const extractChild = (menuItems: Dict<MenuItemRegistration>, child: ReactNode) => {
    if (isValidElement(child)) {
        const menuItem = menuItems[child.key];

        return {
            menuItem,
            child,
        };
    }

    throw `MenuItem is not a valid element`;
};
