import { IconProps } from ".";

export const WindowIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill={fill}>
                <g>
                    <path d="M36,42.2l-13.7-7.9v-20L36,7.9V42.2z M34.3,10.7l-10.6,4.7l0.1,17.9l10.5,6V10.7z" />
                    <path d="M22.6,14.5l13.1-6.1v33.2L22.6,34V14.5z M34.6,10.3l-11.2,4.9l0.1,18.3l11.1,6.4V10.3z" />
                </g>
                <rect x="5.7" y="12.1" width="0.7" height="24.8" />
                <rect x="41" y="11.7" width="0.7" height="25.2" />
                <rect x="5.7" y="11.7" width="22.6" height="0.7" />
                <rect x="34.7" y="11.7" width="6.6" height="0.7" />
                <rect x="38.5" y="14" width="0.7" height="20.6" />
                <rect x="35.6" y="14" width="3" height="0.7" />
                <rect x="5.7" y="36" width="22.1" height="1" />
                <rect x="35.4" y="36.2" width="5.9" height="0.7" />
                <rect x="35.9" y="33.9" width="3" height="0.7" />
                <rect x="19.9" y="14" width="0.7" height="20.6" />
                <rect x="8.5" y="14" width="11.4" height="0.7" />
                <rect x="8.6" y="33.9" width="11.6" height="0.7" />
                <rect x="8.5" y="14" width="0.7" height="20.6" />
            </g>
        </svg>
    );
};
