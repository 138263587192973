import { immerable } from "immer";
import { EPSILON } from "../abstraction";
import { sqr, toRadians } from "./math";

export class Vector2 {
    [immerable] = true;

    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    clone = () => {
        return new Vector2(this.x, this.y);
    };

    add = (vector: Vector2) => {
        return new Vector2(this.x + vector.x, this.y + vector.y);
    };

    subtract = (vector: Vector2) => {
        return new Vector2(this.x - vector.x, this.y - vector.y);
    };

    rotateByDeg = (angle: number) => {
        throw new Error("Method not implemented.");
    };

    angleDeg = () => {
        throw new Error("Method not implemented.");
    };

    dot(vec2: Vector2) {
        return this.x * vec2.x + this.y * vec2.y;
    }

    multiplyScalar(scalar: number) {
        this.x *= scalar;
        this.y *= scalar;
        return this;
    }

    divideScalar(scalar: number) {
        this.x /= scalar;
        this.y /= scalar;
        return this;
    }

    project = (point: Vector2): Vector2 => {
        // https://www.khanacademy.org/math/linear-algebra/matrix-transformations/lin-trans-examples/v/introduction-to-projections

        const projectionConstant = point.dot(this) / this.dot(this);
        const projection = this.clone().multiplyScalar(projectionConstant);

        return projection;
    };

    samePoints = (vec2: Vector2) => {
        return Math.abs(this.x - vec2.x) <= EPSILON && Math.abs(this.y - vec2.y) <= EPSILON;
    };

    dist = (vec2: Vector2): number => {
        let diff_x = this.x - vec2.x;
        let diff_y = this.y - vec2.y;

        return Math.sqrt(diff_x * diff_x + diff_y * diff_y);
    };

    dist2 = (vec2: Vector2): number => {
        return sqr(this.x - vec2.x) + sqr(this.y - vec2.y);
    };

    distToSegmentSquared = (v: Vector2, w: Vector2) => {
        const nearestPoint = this.nearestPointOnSegment(v, w);
        return this.dist2(nearestPoint);
    };

    nearestPointOnSegment = (v: Vector2, w: Vector2): Vector2 => {
        const l2 = v.dist2(w);

        if (l2 === 0) {
            return v;
        }

        const t = ((this.x - v.x) * (w.x - v.x) + (this.y - v.y) * (w.y - v.y)) / l2;

        if (t < 0) {
            return v;
        }
        if (t > 1) {
            return w;
        }

        return new Vector2(v.x + t * (w.x - v.x), v.y + t * (w.y - v.y));
    };

    distToSegment = (v: Vector2, w: Vector2) => {
        return Math.sqrt(this.distToSegmentSquared(v, w));
    };

    getLineEnd = (angle: number, length: number): Vector2 => {
        const vec = new Vector2(-length, 0);
        vec.rotateByDeg(angle);

        const toX = this.x + vec.x;
        const toY = this.y + vec.y;

        return new Vector2(toX, toY);
    };

    fromAngle = (angle: number): Vector2 => {
        const x = Math.cos(toRadians(angle));
        const y = Math.sin(toRadians(angle));

        return new Vector2(x, y);
    };

    transformPointFromAngle = (lineThickness: number, angle: number): Vector2 => {
        return new Vector2(
            this.x + (lineThickness / 2) * Math.cos(toRadians(angle)),
            this.y + (lineThickness / 2) * Math.sin(toRadians(angle)),
        );
    };
}
