export const toRadians = (degrees: number): number => {
    return (degrees * Math.PI) / 180;
};

export const toAngle = (radians: number): number => {
    return (radians * 180) / Math.PI;
};

export const reverseAngleDeg = (angle: number): number => {
    return angle + 180;
};

export const angleDifference = (angle1: number, angle2: number): number => {
    const diff = ((angle2 - angle1 + 180) % 360) - 180;
    return diff < -180 ? diff + 360 : diff;
};

export const positiveAngleDeg = (angle: number): number => {
    return angle <= 0 ? angle + 360 : angle;
};

export const sqr = (x: number): number => {
    return x * x;
};
