import useMeasure from "react-use-measure";
import { ToolbarArea } from "./toolbarArea";
import { ToolbarButton } from "./toolbarButton";
import { ToolbarDropdown } from "./toolbarDropdown";
import { ToolbarGroup } from "./toolbarGroup";
import { ToolbarType, ToolbarGroupType } from "./types";
import { useMemo } from "react";
import { ToolbarToggleButton } from "./toolbarToggleButton";

interface Props {
    toolbar: ToolbarType;
}

export const Toolbar = ({ toolbar }: Props) => {
    const [ref, bounds] = useMeasure();

    const collapsedGroups = useMemo(() => {
        const result: Record<string, { width: number; order: number; collapsed: boolean }> = Object.assign(
            {},
            ...Object.keys(toolbar.groups).map((groupKey) => {
                const group = toolbar.groups[groupKey];

                const width = Object.keys(group.items)
                    .map((item) => group.items[item])
                    .map((item) => {
                        switch (item.type) {
                            case "Button":
                                return 64;

                            case "ToggleButton":
                                return 64;

                            case "Dropdown":
                                return 96;

                            case "ToggleGroup":
                                return 0;

                            default:
                                return 0;
                        }
                    })
                    .reduce((a, b) => a + b, 0);

                const paddings = 8 + 8;
                const groupWidth = width + paddings;

                return {
                    [groupKey]: {
                        width: groupWidth,
                        order: group.collapseOrder,
                        collapsed: false,
                    },
                };
            }),
        );

        const calculateFits = () => {
            const totalWidth = Object.keys(result)
                .map((width) => result[width])
                .reduce((a, b) => a + (b.collapsed ? 80 : b.width), 0);

            return totalWidth < bounds.width;
        };

        var fits = calculateFits();

        while (!fits && bounds.width > 0) {
            const nextToCollapse = Object.keys(result)
                .map((width) => result[width])
                .filter((width) => !width.collapsed)
                .sort((a, b) => a.order - b.order);

            if (nextToCollapse.length > 0) {
                nextToCollapse[0].collapsed = true;
            } else {
                break;
            }

            fits = calculateFits();
        }

        return result;
    }, [bounds.width, toolbar.groups]);

    const createToolbarGroup = (groupKey: string, collapsed: boolean, group: ToolbarGroupType) => {
        const itemComponents = Object.keys(group.items)
            .map((itemKey) => ({ itemKey, item: group.items[itemKey] }))
            .sort(({ item: itemA }, { item: itemB }) => (itemA.order ?? 1000) - (itemB.order ?? 1000))
            .map(({ itemKey, item }) => {
                switch (item.type) {
                    case "Button":
                        return <ToolbarButton key={itemKey} {...item} />;

                    case "ToggleButton":
                        return <ToolbarToggleButton key={itemKey} {...item} />;

                    case "Dropdown":
                        return <ToolbarDropdown key={itemKey} {...item} />;

                    case "ToggleGroup":
                        return null;
                    // return (<ToolbarToggleGroup {...item} />);

                    default:
                        return null;
                }
            });

        return (
            <ToolbarGroup key={groupKey} collapsed={collapsed} {...group}>
                {itemComponents}
            </ToolbarGroup>
        );
    };

    const groupComponents = Object.keys(toolbar.groups)
        .map((groupKey) => ({ groupKey, group: toolbar.groups[groupKey] }))
        .sort(({ group: groupA }, { group: groupB }) => (groupA.order ?? 1000) - (groupB.order ?? 1000))
        .map(({ group, groupKey }) => {
            const collapsed = collapsedGroups[groupKey].collapsed;

            return createToolbarGroup(groupKey, collapsed, group);
        });

    return (
        <div ref={ref} className="select-none">
            <ToolbarArea>{groupComponents}</ToolbarArea>
        </div>
    );
};
