import { useCallback, useEffect } from "react";
import { useKeyPress } from "@stylister/keyboard";
import { useStore } from "../store";

export const HistoryComponent = () => {
    const escPress = useKeyPress("Escape");
    const store = useStore();

    const drawing = store((state) => state.drawing);
    const selectNoTool = store((state) => state.selectNoTool);
    const undo = store((state) => state.undo);

    const cancelDrawing = useCallback(() => {
        if (drawing.dirty) {
            undo(false);
        }

        selectNoTool();
    }, [drawing, undo, selectNoTool]);

    useEffect(() => {
        if (escPress && drawing) {
            cancelDrawing();
        }
    }, [escPress, drawing]);

    return null;
};
