//MODES
export const MODE_IDLE = "MODE_IDLE";
export const MODE_2D_ZOOM_IN = "MODE_2D_ZOOM_IN";
export const MODE_2D_ZOOM_OUT = "MODE_2D_ZOOM_OUT";
export const MODE_2D_PAN = "MODE_2D_PAN";
export const MODE_WAITING_DRAWING_LINE = "MODE_WAITING_DRAWING_LINE";
export const MODE_DRAGGING_LINE = "MODE_DRAGGING_LINE";
export const MODE_DRAGGING_VERTEX = "MODE_DRAGGING_VERTEX";
export const MODE_DRAGGING_ITEM = "MODE_DRAGGING_ITEM";
export const MODE_DRAGGING_HOLE = "MODE_DRAGGING_HOLE";
export const MODE_DRAWING_LINE = "MODE_DRAWING_LINE";
export const MODE_DRAWING_HOLE = "MODE_DRAWING_HOLE";
export const MODE_DRAWING_ITEM = "MODE_DRAWING_ITEM";
export const MODE_ROTATING_ITEM = "MODE_ROTATING_ITEM";
export const MODE_UPLOADING_IMAGE = "MODE_UPLOADING_IMAGE";
export const MODE_FITTING_IMAGE = "MODE_FITTING_IMAGE";

export type Mode =
    | typeof MODE_IDLE
    | typeof MODE_2D_ZOOM_IN
    | typeof MODE_2D_ZOOM_OUT
    | typeof MODE_2D_PAN
    | typeof MODE_WAITING_DRAWING_LINE
    | typeof MODE_DRAWING_LINE
    | typeof MODE_DRAWING_HOLE
    | typeof MODE_DRAWING_ITEM
    | typeof MODE_DRAGGING_LINE
    | typeof MODE_DRAGGING_VERTEX
    | typeof MODE_DRAGGING_ITEM
    | typeof MODE_DRAGGING_HOLE
    | typeof MODE_FITTING_IMAGE
    | typeof MODE_UPLOADING_IMAGE
    | typeof MODE_ROTATING_ITEM;

export const MODE_SNAPPING = [
    MODE_IDLE,
    MODE_2D_ZOOM_IN,
    MODE_2D_ZOOM_OUT,
    MODE_2D_PAN,
    MODE_WAITING_DRAWING_LINE,
    MODE_DRAWING_LINE,
    MODE_DRAWING_HOLE,
    MODE_DRAWING_ITEM,
    MODE_DRAGGING_LINE,
    MODE_DRAGGING_VERTEX,
    MODE_DRAGGING_ITEM,
    MODE_DRAGGING_HOLE,
    MODE_FITTING_IMAGE,
    MODE_UPLOADING_IMAGE,
    MODE_ROTATING_ITEM,
];

//UNITS
export const UNIT_MILLIMETER = "mm";
export const UNIT_CENTIMETER = "cm";
export const UNIT_METER = "m";
export const UNIT_INCH = "in";
export const UNIT_FOOT = "ft";
export const UNIT_MILE = "mi";

export const UNITS_LENGTH = [UNIT_MILLIMETER, UNIT_CENTIMETER, UNIT_METER, UNIT_INCH, UNIT_FOOT, UNIT_MILE];

export const EPSILON = 1e-6;
