interface Props {
    label: string;
}

export const SidebarCategoryHeader = ({ label }: Props) => {
    return (
        <p key={label} className="text-white group flex items-center px-6 py-2 text-sm font-light uppercase">
            {label}
        </p>
    );
};
