import { NamedSet } from "zustand/middleware";
import { Scene } from "../scene";
import { State } from "../store";
import { current } from "immer";

export interface HistorySlice {
    undos: Scene[];
    redos: Scene[];
    canUndo: boolean;
    undo: (redoable: boolean) => void;
    canRedo: boolean;
    redo: () => void;
    saveHistory: () => void;
}

export const createHistorySlice = (set: NamedSet<State>): HistorySlice => ({
    undos: [],
    redos: [],
    canUndo: false,
    undo: (redoable: boolean) =>
        set(
            (state: State) => {
                undo(state, redoable);
            },
            false,
            "undo",
        ),
    canRedo: false,
    redo: () =>
        set(
            (state: State) => {
                redo(state);
            },
            false,
            "redo",
        ),
    saveHistory: () =>
        set(
            (state: State) => {
                saveHistory(state);
            },
            false,
            "saveHistory",
        ),
});

export const undo = (state: State, redoable: boolean) => {
    // if (state.canUndo) {
    //     const currentScene = current(state.scene);
    //     const scene = state.undos.pop();
    //     state.canUndo = state.undos.length > 0;
    //     if (redoable) {
    //         state.redos.push(currentScene);
    //         state.canRedo = true;
    //     }
    //     state.scene = scene;
    // }
};

export const redo = (state: State) => {
    // if (state.canRedo) {
    //     const currentScene = current(state.scene);
    //     const scene = state.redos.pop();
    //     state.canRedo = state.redos.length > 0;
    //     state.undos.push(currentScene);
    //     state.canUndo = true;
    //     state.scene = scene;
    // }
};

export const saveHistory = (state: State) => {
    // const currentScene = current(state.scene);
    // state.undos.push(currentScene);
    // state.canUndo = true;
};
