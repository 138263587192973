import { IconProps } from "./types";

export const ChangesIcon = ({ fill, size }: IconProps) => {
    return (
        <svg height={size} width={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill={fill}>
                <g fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                    <path d="M12 3a9.002 9.002 0 0 0-8.294 5.5" />
                    <path d="M21 12a9 9 0 0 0-9-9" />
                    <path d="M12 21a9 9 0 0 0 9-9" />
                    <path d="M3.059 13A8.999 8.999 0 0 0 12 21" />
                    <path d="M6 8.5H3v-3" />
                    <path d="M11.159 15.427H8.573v-2.586a1 1 0 0 1 .293-.707l4-4a1 1 0 0 1 1.414 0l1.586 1.586a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-.707.293z" />
                </g>
                <path d="M24 24H0V0h24z" fill="none" />
            </g>
        </svg>
    );
};
